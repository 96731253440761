define("additive-content/validations/settings/domain", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.domainSettingsValidation = void 0;
  const domainSettingsValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const regex = /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*([a-zA-Z0-9][a-zA-Z0-9-_]*)+\.[a-zA-Z]{2,}$/;
    return {
      name: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateFormat)({
        regex,
        message: intl.t('errors.domainInvalid'),
        allowBlank: true
      })]
    };
  };
  _exports.domainSettingsValidation = domainSettingsValidation;
});