define("additive-content/templates/instance/landing-pages/landing-page/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ihLPjnnM",
    "block": "[[[8,[39,0],null,[[\"@backRoute\",\"@isReadOnly\",\"@model\"],[\"instance.landing-pages.landing-page\",[30,0,[\"isViewer\"]],[30,0,[\"model\"]]]],null]],[],false,[\"ac-landing-pages/configurator\"]]",
    "moduleName": "additive-content/templates/instance/landing-pages/landing-page/edit.hbs",
    "isStrictMode": false
  });
});