define("additive-content/mirage/routes/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Tags = void 0;
  const Tags = server => {
    server.get('/tags', schema => {
      return schema.tags.all();
    });
  };
  _exports.Tags = Tags;
});