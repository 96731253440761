define("additive-content/mirage/routes/portal-scores", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PortalScores = void 0;
  const PortalScores = server => {
    server.get('/portal-scores/portals', schema => {
      return schema.portalScores.all();
    });
  };
  _exports.PortalScores = PortalScores;
});