define("additive-content/mirage/routes/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Categories = void 0;
  const Categories = server => {
    server.get('/categories', (schema, _ref) => {
      let {
        queryParams
      } = _ref;
      const type = queryParams['categorizableType'];
      return schema.categories.where({
        categorizableType: type
      });
    });
  };
  _exports.Categories = Categories;
});