define("additive-content/validations/timespan", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timespanValidation = _exports.timespanRoomValidation = void 0;
  const timespanValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const greaterZeroMessage = intl.t('errors.gtZero');
    return {
      startsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      endsAt: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })],
      amount: [(0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })]
    };
  };
  _exports.timespanValidation = timespanValidation;
  const timespanRoomValidation = intl => {
    const greaterZeroMessage = intl.t('errors.gtZero');
    return {
      amount: [(0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })]
    };
  };
  _exports.timespanRoomValidation = timespanRoomValidation;
});