define("additive-content/templates/instance/contents/offers/index/offer/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HloBUiZP",
    "block": "[[[41,[30,0,[\"model\",\"description\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@label\",\"@content\"],[[28,[37,2],[\"contents.common.properties.description\"],null],[30,0,[\"model\",\"description\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"includedServices\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@label\",\"@content\"],[[28,[37,2],[\"contents.offers.offer.properties.includedServices\"],null],[30,0,[\"model\",\"includedServices\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"images\",\"length\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"contents.common.properties.images\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@hasImageLabels\",\"@modelName\",\"@changeset\",\"@isReadOnly\"],[true,\"offers\",[30,0,[\"model\"]],true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"_allInterestTags\",\"length\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"contents.common.interestTags.title\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,4],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"_allInterestTags\"]],true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"ac-contents/detail/wrapper\",\"t\",\"ac-contents/detail/images\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/offers/index/offer/index.hbs",
    "isStrictMode": false
  });
});