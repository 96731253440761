define("additive-content/routes/instance/contents/posts/index/post/comments", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsPostsIndexPostCommentsRoute extends _route.default {
    setupController(controller) {
      super.setupController(...arguments);
      controller.fetchPeople.perform();
      controller.fetchComments.perform();
    }
  }
  _exports.default = InstanceContentsPostsIndexPostCommentsRoute;
});