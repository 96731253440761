define("additive-content/routes/instance/contents/offers/index/offer/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsOffersIndexOfferIndexRoute extends _route.default {
    model() {
      return super.model(...arguments);
    }
  }
  _exports.default = InstanceContentsOffersIndexOfferIndexRoute;
});