define("additive-content/serializers/content-type", ["exports", "additive-content/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NULLABLE_PROPERTIES = ['image'];
  var _default = _exports.default = _application.default.extend({
    primaryKey: 'type',
    nullableProperties: NULLABLE_PROPERTIES
  });
});