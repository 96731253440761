define("additive-content/routes/instance/contents/hotel-infos/categories/create", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    uiAppSettings: (0, _service.inject)(),
    currentLocale: _object.computed.alias('uiAppSettings.currentLocale'),
    model() {
      return this.store.createRecord('category', {
        language: this.currentLocale,
        categorizableType: 'hotel_info'
      });
    }
  });
});