define("additive-content/helpers/bool", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bool = bool;
  _exports.default = void 0;
  function bool(_ref) {
    let [arg] = _ref;
    return !!arg;
  }
  var _default = _exports.default = (0, _helper.helper)(bool);
});