define("additive-content/mirage/scenarios/test/default", ["exports", "additive-content/mirage/scenarios/organization", "additive-content/mirage/scenarios/user"], function (_exports, _organization, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ORG3_ID = _exports.DEFAULT_ORG2_ID = _exports.DEFAULT_ORG1_ID = void 0;
  const DEFAULT_ORG1_ID = _exports.DEFAULT_ORG1_ID = 'test-org';
  const DEFAULT_ORG2_ID = _exports.DEFAULT_ORG2_ID = 'test2-org';
  const DEFAULT_ORG3_ID = _exports.DEFAULT_ORG3_ID = 'test2-org';
  var _default = _exports.default = function _default(server) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const _options = Object.assign({
      loadOrganization: true,
      loadUser: true
    }, options);
    server.create('organization', {
      id: DEFAULT_ORG1_ID,
      name: DEFAULT_ORG1_ID,
      isPartner: false
    });
    server.create('organization', {
      id: DEFAULT_ORG2_ID,
      name: DEFAULT_ORG2_ID,
      isPartner: false
    });
    server.create('organization', {
      id: DEFAULT_ORG3_ID,
      name: DEFAULT_ORG3_ID,
      isPartner: false
    });
    server.create('user');
    server.create('user', {
      role: 'manager'
    });
    server.create('user', {
      role: 'admin'
    });
    server.create('user', {
      isAdditive: false,
      isPartner: false
    });
    server.create('user', {
      isAdditive: false,
      isPartner: false,
      role: 'manager'
    });
    server.create('user', {
      isAdditive: false,
      isPartner: false,
      role: 'admin'
    });
    _options.loadOrganization && (0, _organization.starter)(server, DEFAULT_ORG1_ID);
    _options.loadOrganization && (0, _organization.starter)(server, DEFAULT_ORG2_ID);
    _options.loadOrganization && (0, _organization.starter)(server, DEFAULT_ORG3_ID);
    _options.loadUser && (0, _user.partnerMember)(server);
    _options.loadAdminUser && (0, _user.admin)(server);
    _options.loadAdditiveAdminUser && (0, _user.additiveAdmin)(server);
  };
});