define("additive-content/routes/instance/contents/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsIndexRoute extends _route.default {
    setupController(controller) {
      super.setupController(...arguments);
      controller.fetchContentTypes.perform();
    }
  }
  _exports.default = InstanceContentsIndexRoute;
});