define("additive-content/templates/instance/contents/surveys/categories/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ubomaMQ0",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@contentTypeRoute\",\"@isCreate\"],[[30,0,[\"model\"]],\"surveys\",true]],[[\"default\"],[[[[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"ac-contents/categories/detail\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/surveys/categories/create.hbs",
    "isStrictMode": false
  });
});