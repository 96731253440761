define("additive-content/mirage/routes/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Settings = void 0;
  const Settings = server => {
    server.get('/settings/last-minute-offers', () => {
      return {
        lastMinuteOfferSettings: {
          sync: true
        }
      };
    });
    server.get('/settings/rooms', () => {
      return {
        roomSettings: {
          syncPrices: true,
          syncRooms: true
        }
      };
    });
    server.get('/settings/general', () => {
      return {
        settings: {
          email: 'dev@additive-apps.eu',
          phone: '0473 538800',
          name: 'Testhotel Post',
          address: 'Industriezone 1/5, 39011 Lana, Südtirol, Italien'
        }
      };
    });
    server.get('/settings/integrations', () => {
      return {
        integrations: {
          syncWithPms: true,
          canImport: true,
          pms: 'asa'
        }
      };
    });
    server.put('/settings/integrations', (schema, request) => {
      const requestBody = JSON.parse(request.requestBody);
      return {
        integrations: requestBody
      };
    });
    server.get('/settings/domains', () => {
      return {
        domains: [{
          name: 'testhotel-post-com.staging.additive-apps.site',
          verified: true,
          primary: true,
          cname: 'cname.testhotel-post-com.staging.additive-apps.site'
        }, {
          name: 'surveys.testhotel-post.com',
          verified: true,
          primary: false,
          cname: 'cname.surveys.testhotel-post.com'
        }, {
          name: 'redirect.testhotel-post.com',
          verified: true,
          primary: false,
          cname: 'cname.redirect.testhotel-post.com'
        }, {
          name: 'lp.testhotel-post.com',
          verified: false,
          primary: false,
          cname: 'cname.lp.testhotel-post.com'
        }, {
          name: 'survey.testhotel-post.com',
          verified: false,
          primary: false,
          cname: 'cname.survey.testhotel-post.com'
        }]
      };
    });
    server.get('/settings/legals', () => {
      return {
        legal: {
          disclaimerLink: {
            sync: true,
            syncedValue: 'https://www.additive-apps.eu/impressum.html'
          },
          privacyLink: {
            value: 'http://www.testhotel-post.com/impressum/',
            sync: true,
            syncedValue: 'https://www.additive-apps.eu/datenschutz.html'
          },
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'nl', 'fr', 'pl']
        }
      };
    });
    server.put('/settings/legals', (schema, request) => {
      const requestBody = JSON.parse(request.requestBody);
      return {
        legal: Object.assign({
          disclaimerLink: {
            sync: true,
            syncedValue: 'https://www.additive-apps.eu/impressum.html'
          },
          privacyLink: {
            value: 'http://www.testhotel-post.com/impressum/',
            sync: true,
            syncedValue: 'https://www.additive-apps.eu/datenschutz.html'
          },
          language: 'de',
          availableLanguages: ['de', 'en', 'it', 'nl', 'fr', 'pl']
        }, requestBody)
      };
    });
  };
  _exports.Settings = Settings;
});