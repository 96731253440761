define("additive-content/validations/survey", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/validate-if", "additive-content/validators/surveyOptions"], function (_exports, _validators, _validateIf, _surveyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.surveyValidation = void 0;
  const surveyValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const uniqueOptionsMessage = intl.t('contents.surveys.survey.errors.uniqueQuestionOptions');
    const minOptionsMessage = intl.t('contents.surveys.survey.errors.minQuestionOptionsLength');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      questions: [
      // message is shown externally through notification
      (0, _validators.validateLength)({
        min: 1,
        allowBlank: false,
        message: ''
      }), (0, _surveyOptions.validateQuestionOptionUniqueness)({
        message: uniqueOptionsMessage
      }), (0, _surveyOptions.validateQuestionOptionLength)({
        message: minOptionsMessage
      })],
      targetId: (0, _validateIf.default)({
        validate: (0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        }),
        if: (key, newValue, oldValue, changes) => {
          return changes.targetType === 'landingPage';
        },
        firstErrorMessage: true
      })
    };
  };
  _exports.surveyValidation = surveyValidation;
});