define("additive-content/controllers/instance/contents/index", ["exports", "@ember/controller", "@ember/service", "ember-concurrency", "@ember/object"], function (_exports, _controller, _service, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    store: (0, _service.inject)(),
    /**
     * whether the organization has contents
     *
     * @computed hasContents
     * @type {Boolean}
     */
    hasContents: _object.computed.or('contents.contentTypes.length', 'contents.services.length'),
    /**
     * whether the fetch task is running
     *
     * @computed isLoading
     * @type {Boolean}
     */
    isLoading: _object.computed.alias('fetchContentTypes.isRunning'),
    /**
     * An object containing bothe the main and other content types
     *
     * @computed contents
     * @type {Object}
     */
    contents: (0, _object.computed)('contentTypes.{[],@each.active}', {
      get() {
        var _this$contentTypes, _this$contentTypes2;
        return {
          contentTypes: (_this$contentTypes = this.contentTypes) === null || _this$contentTypes === void 0 ? void 0 : _this$contentTypes.filter(content => content.category === 'content'),
          services: (_this$contentTypes2 = this.contentTypes) === null || _this$contentTypes2 === void 0 ? void 0 : _this$contentTypes2.filter(content => content.category === 'product_or_service')
        };
      }
    }),
    /**
     * fetches the content types and filters not enabled ones
     *
     * @function fetchContentTypes
     * @type {Task}
     */
    fetchContentTypes: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'isFetchError', false);
        const tasks = [this.store.findAll('content-type'), (0, _emberConcurrency.timeout)(400)];
        const [contentTypes] = yield (0, _emberConcurrency.all)(tasks);
        (0, _object.set)(this, 'contentTypes', contentTypes);
      } catch (e) {
        (0, _object.set)(this, 'isFetchError', true);
      }
    })
  });
});