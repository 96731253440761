define("additive-content/validations/point-of-interest", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.poiValidation = void 0;
  const poiValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const emailMessage = intl.t('errors.email');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      email: (0, _validators.validateFormat)({
        type: 'email',
        allowBlank: true,
        message: emailMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.poiValidation = poiValidation;
});