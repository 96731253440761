define("additive-content/mirage/factories/last-minute-offer", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.commerce.product(),
    discountedPrice: () => _faker.default.random.number(),
    price: () => _faker.default.random.number() * 10,
    startDate: () => _faker.default.date.future(),
    endDate: () => _faker.default.date.future(),
    language: 'de',
    publishedLanguages: () => ['de'],
    rating: _faker.default.random.number({
      min: 1,
      max: 3
    }),
    timeLimited: false
  });
});