define("additive-content/components/ui-navigation-bar", ["exports", "@additive-apps/ui/components/ui-navigation-bar"], function (_exports, _uiNavigationBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiNavigationBar.default;
    }
  });
});