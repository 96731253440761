define("additive-content/helpers/array-contains", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.arrayContains = void 0;
  const arrayContains = params => {
    let array = [];
    let element = null;
    [array, element] = params;
    return !!array.find(item => item === element);
  };
  _exports.arrayContains = arrayContains;
  var _default = _exports.default = (0, _helper.helper)(arrayContains);
});