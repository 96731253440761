define("additive-content/templates/instance/contents/daily-menus/index/daily-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "9N8Z0YbI",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@routeName\",\"@model\"],[\"daily-menus\",\"daily-menu\",\"daily-menu\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@classNames\"],[\"mb2\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.dailyMenus.dailyMenu.properties.date\"],null],[28,[37,4],[[30,0,[\"model\",\"date\"]]],[[\"format\"],[[28,[37,5],null,[[\"de\",\"en\"],[\"dd.MM.yyyy\",\"dd-MM-yyyy\"]]]]]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"courses\"]],[[[1,\"      \"],[8,[39,7],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.dailyMenus.dailyMenu.properties.courses\"],null],[30,0,[\"model\",\"courses\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"tags\",\"length\"]],[[[1,\"      \"],[8,[39,7],null,[[\"@label\"],[[28,[37,3],[\"contents.common.properties.tags\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"tags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"ui-content-info\",\"ui-info-item\",\"t\",\"date-format\",\"hash\",\"if\",\"ac-contents/detail/wrapper\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/daily-menus/index/daily-menu.hbs",
    "isStrictMode": false
  });
});