define("additive-content/serializers/page", ["exports", "additive-content/serializers/contents"], function (_exports, _contents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PageSerializer extends _contents.default {
    serialize(snapshot, options) {
      var _json$interests, _json$travelTimes, _json$travelMotivatio;
      const json = super.serialize(snapshot, options);
      if (!json.link) {
        json.link = null;
      }
      if (!json.linkType || !json.link) {
        json.linkType = null;
      }

      // transform interests to string array
      json.interests = (_json$interests = json.interests) === null || _json$interests === void 0 ? void 0 : _json$interests.map(interest => interest.id ? interest.id : interest);
      json.travelTimes = (_json$travelTimes = json.travelTimes) === null || _json$travelTimes === void 0 ? void 0 : _json$travelTimes.map(travelTime => travelTime.id ? travelTime.id : travelTime);
      json.travelMotivations = (_json$travelMotivatio = json.travelMotivations) === null || _json$travelMotivatio === void 0 ? void 0 : _json$travelMotivatio.map(travelMotivation => travelMotivation.id ? travelMotivation.id : travelMotivation);
      return json;
    }
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      super.normalizeResponse(...arguments);

      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties and sort images
      contents.forEach(content => {
        if (!content.link) {
          content.link = null;
        }
        if (!content.linkType) {
          content.linkType = null;
        }
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  _exports.default = PageSerializer;
});