define("additive-content/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "PIp5j5V9",
    "block": "[[[10,0],[14,0,\"application-view flex w-100\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"accountSwitch\"]],null,[[\"@onOrganizationClick\"],[[30,0,[\"transitionToOrganizationRoute\"]]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"instanceRoutes\"]]],null]],null],null,[[[1,\"          \"],[8,[30,1,[\"menuItem\"]],null,[[\"@title\",\"@icon\",\"@routePath\"],[[28,[37,4],[[30,2,[\"intlKey\"]]],null],[30,2,[\"svg\"]],[30,2,[\"path\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"\\n        \"],[8,[30,1,[\"menuItem\"]],null,[[\"@title\",\"@icon\",\"@href\"],[[28,[37,4],[\"routes.help.title\"],null],\"help\",[30,0,[\"helpUrl\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[30,1,[\"appsList\"]],null,null,null],[1,\"\\n      \"],[8,[30,1,[\"userButton\"]],null,null,null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\\n  \"],[10,0],[14,1,\"mm-fullscreen-view-container\"],[12],[13],[1,\"\\n  \"],[10,0],[14,1,\"ember-basic-dropdown-wormhole\"],[12],[13],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n  \"],[8,[39,8],null,[[\"@parent\"],[\"body\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"onboarding\"]],[[[1,\"    \"],[8,[39,9],null,[[\"@appNameSvg\",\"@activeOnboarding\",\"@onboarding\",\"@onFinish\"],[\"app-name-content\",[30,0,[\"onboarding\"]],[30,0,[\"onboardingOptions\"]],[30,0,[\"finishOnboarding\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"applicationNavDrawer\",\"route\"],false,[\"if\",\"ui-application-navigation-drawer\",\"each\",\"-track-array\",\"t\",\"component\",\"-outlet\",\"ui-dialog-service\",\"ui-toast-container\",\"ui-onboarding\"]]",
    "moduleName": "additive-content/templates/application.hbs",
    "isStrictMode": false
  });
});