define("additive-content/templates/instance/multimedia/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "24mkwXm2",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.multimedia.title\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,[[\"@intersectionTargetSelectorId\",\"@hasCustomSentinel\"],[\"ui-navigation-bar-sentinel\",true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"action\"]],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"search\",[28,[37,1],[\"uiActions.search\"],null],[30,0,[\"openFilter\"]]]],null],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,0,[\"multimedia\",\"currentFolder\"]],[28,[37,4],[[30,0,[\"multimedia\",\"currentFolder\",\"shared\"]]],null],[28,[37,4],[[30,0,[\"isViewer\"]]],null]],null],[[[1,\"      \"],[8,[30,2,[\"action\"]],null,[[\"@label\",\"@icon\",\"@onClick\"],[[28,[37,1],[\"multimedia.bulkDownload.download\"],null],\"download\",[28,[37,5],[[30,0,[\"onDownloadFolder\"]],[30,0,[\"multimedia\",\"currentFolder\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,1,\"ui-navigation-bar-sentinel\"],[14,0,\"relative\"],[12],[13],[1,\"\\n    \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\",\"navbar\"],false,[\"ui-content-views\",\"t\",\"if\",\"and\",\"not\",\"fn\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/multimedia/index.hbs",
    "isStrictMode": false
  });
});