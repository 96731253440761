define("additive-content/mirage/routes/render-service", ["exports", "additive-content/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RenderService = void 0;
  const RenderService = server => {
    server.post(`${_environment.default.APP.renderHost}/blocks`, (schema, request) => {
      const response = {
        layout: `<html><head><style>p {font-size: 24px;}</style></head><body><div style="background:black; padding:54px;">{{CONTENT_BLOCKS}}</div></body></html>`,
        blocks: []
      };
      const requestBody = JSON.parse(request.requestBody);
      requestBody.blocks.forEach(block => {
        var _block$style, _block$content;
        const backgroundColor = ((_block$style = block.style) === null || _block$style === void 0 ? void 0 : _block$style.backgroundColor) || ['none', 'lightblue', 'red', 'green'][Math.floor(Math.random() * 4)];
        response.blocks.push(`<div style="background:${backgroundColor};height:200px;width:600px;max-width: 100%;padding:48px;margin:0 auto;box-sizing:border-box;">
            <p>${((_block$content = block.content) === null || _block$content === void 0 ? void 0 : _block$content.title) || ''}</p>
          </div>`);
      });
      return response;
    }, {
      timing: 100
    });
    server.post(`${_environment.default.APP.renderHost}/layout`, (schema, request) => {
      let html = '<html><head><style>p {font-size: 24px;}</style></head><body><div style="padding:54px;">';
      const requestBody = JSON.parse(request.requestBody);
      requestBody.blocks.forEach(block => {
        var _block$style2, _block$content2;
        const backgroundColor = ((_block$style2 = block.style) === null || _block$style2 === void 0 ? void 0 : _block$style2.backgroundColor) || ['none', 'lightblue', 'red', 'green'][Math.floor(Math.random() * 4)];
        html += `<div style="background:${backgroundColor};height:200px;width:600px;max-width: 100%;padding:48px;margin:0 auto;box-sizing:border-box;">
            <p>${((_block$content2 = block.content) === null || _block$content2 === void 0 ? void 0 : _block$content2.title) || ''}</p>
          </div>`;
      });
      html += '</div></body></html>';
      return html;
    }, {
      timing: 100
    });
  };
  _exports.RenderService = RenderService;
});