define("additive-content/controllers/instance/contents/quotes/index/quote", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsQuotesIndexQuoteController extends _controller.default {
    get score() {
      const {
        score,
        scoreMax
      } = this.model;
      if (typeof score !== 'number') {
        return null;
      }
      let scoreString = score;
      if (typeof scoreMax === 'number') {
        scoreString = `${score} / ${scoreMax}`;
      }
      return scoreString;
    }
  }
  _exports.default = InstanceContentsQuotesIndexQuoteController;
});