define("additive-content/templates/instance/contents/posts/redirected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "z16kT7KE",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@size\",\"@onClose\"],[[28,[37,1],[\"routes.redirected.title\"],null],\"sm\",[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"header\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"routes.redirected.description\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@label\",\"@onClick\",\"@isSecondary\",\"@isPromise\"],[[28,[37,1],[\"global.actions.close\"],null],[30,0,[\"close\"]],true,true]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"m\",\"c\"],false,[\"ui-modal\",\"t\",\"ui-button\"]]",
    "moduleName": "additive-content/templates/instance/contents/posts/redirected.hbs",
    "isStrictMode": false
  });
});