define("additive-content/mirage/factories/style-block", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.lorem.word(),
    active: () => _faker.default.random.boolean(),
    blockName: () => _faker.default.lorem.word(),
    blockIdentifier: () => _faker.default.random.arrayElement(['header', 'footer', 'text', 'spacer', 'voucher-widget']),
    content: () => [{
      id: 'contents',
      properties: [{
        id: 'title',
        name: 'Titel',
        type: 'string',
        value: 'Platzhalter Titel'
      }, {
        id: 'text',
        name: 'Text',
        type: 'string',
        value: ''
      }, {
        id: 'link',
        name: 'Link',
        type: 'link',
        value: {
          text: 'link',
          url: 'additive.eu'
        }
      }]
    }],
    style: () => [{
      id: 'layout',
      properties: [{
        id: 'alignment',
        name: 'Ausrichtung',
        type: 'enum',
        value: 'left',
        allowedValues: ['left', 'center', 'right']
      }]
    }, {
      id: 'padding',
      name: 'Abstände',
      properties: [{
        id: 'paddingTop',
        name: 'Abstand oben',
        type: 'number',
        value: 32
      }, {
        id: 'paddingBottom',
        name: 'Abstand unter',
        type: 'number',
        value: 32
      }]
    }, {
      id: 'border',
      name: 'Rahmen',
      properties: [{
        id: 'borderRadius',
        name: 'Abrundung',
        type: 'number',
        value: 2,
        sync: true,
        syncedValue: 2
      }]
    }, {
      id: 'background',
      name: 'Hintergrund',
      properties: [{
        id: 'backgroundColor',
        name: 'Hintergrundfarbe',
        type: 'color',
        value: _faker.default.random.arrayElement(['red', 'green', 'yellow', 'blue', 'violet']),
        sync: false,
        syncedValue: 'ambient'
      }]
    }, {
      id: 'corporate-design',
      name: 'CD',
      properties: [{
        id: 'typography',
        name: 'Schrift',
        type: 'typography',
        value: {
          fontFamily: 'primary.normal',
          fontSize: '14px',
          lineHeight: '20px',
          weight: 'normal'
        },
        sync: true,
        syncedValue: 'h1'
      }, {
        id: 'typography',
        name: 'Schrift',
        type: 'typography',
        sync: true,
        syncedValue: 'h1'
      }, {
        id: 'typographyColor',
        name: 'Farbe',
        type: 'typography_color',
        value: 'main',
        sync: true,
        syncedValue: 'ambient'
      }]
    }],
    afterCreate(model, server) {
      var _server$schema$styleB;
      const existingStyleBlocks = (_server$schema$styleB = server.schema.styleBlocks) === null || _server$schema$styleB === void 0 ? void 0 : _server$schema$styleB.all();
      const existingBlocksWithId = existingStyleBlocks.filter(styleBlocks => styleBlocks.blockIdentifier === model.blockIdentifier);
      const variationPosition = existingBlocksWithId.length;
      model.update({
        variationIdentifier: `${model.blockIdentifier}-${variationPosition}`,
        variationPosition: variationPosition
      });
    }
  });
});