define("additive-content/templates/instance/landing-pages", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "jxOupPWT",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@detailRoute\",\"@theme\"],[[28,[37,1],[\"landingPages.title\"],null],\"instance.landing-pages.landing-page.index\",\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@description\",\"@size\",\"@isMultiline\"],[[28,[37,1],[\"landingPages.title\"],null],[28,[37,1],[\"landingPages.description\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@detailRoute\"],[\"instance.landing-pages.landing-page\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"hasCreateButton\"]],[[[1,\"      \"],[8,[39,4],[[24,\"data-test-landing-page-add-button\",\"\"]],[[\"@class\",\"@icon\",\"@isFabButton\",\"@onClick\"],[\"absolute bottom-3 right-3\",\"plus\",true,[28,[37,5],[[28,[37,6],[[30,0,[\"isCreateDialog\"]]],null],true],null]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isCreateDialog\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@onClose\"],[[28,[37,5],[[28,[37,6],[[30,0,[\"isCreateDialog\"]]],null],false],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,9],[\"detail\"],null],null,null,null]],[\"view\"],false,[\"ui-content-views\",\"t\",\"ac-landing-pages/list\",\"if\",\"ui-button\",\"fn\",\"mut\",\"ac-landing-pages/detail-dialog\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/landing-pages.hbs",
    "isStrictMode": false
  });
});