define("additive-content/components/aw-font-name-dialog", ["exports", "@additive-apps/configurator/components/aw-font-name-dialog"], function (_exports, _awFontNameDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _awFontNameDialog.default;
    }
  });
});