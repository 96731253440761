define("additive-content/serializers/timespan-room", ["exports", "additive-content/serializers/contents"], function (_exports, _contents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _contents.default.extend({
    primaryKey: 'roomId'
  });
});