define("additive-content/templates/instance/contents/room-services/index/room-service", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5/CHFOQF",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@model\"],[\"room-services\",\"room-service\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"amount\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@classNames\"],[\"mb2\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@label\",\"@content\"],[[28,[37,4],[\"contents.common.properties.amount\"],null],[28,[37,5],[[30,0,[\"model\",\"amount\"]],[30,0,[\"uiAppSettings\",\"currency\"]],[30,0,[\"currentUser\",\"user\",\"language\"]]],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"description\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@label\",\"@content\"],[[28,[37,4],[\"contents.common.properties.description\"],null],[30,0,[\"model\",\"description\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"images\",\"length\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@label\"],[[28,[37,4],[\"contents.common.properties.images\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,7],null,[[\"@changeset\",\"@isReadOnly\"],[[30,0,[\"model\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"_allInterestTags\",\"length\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@label\"],[[28,[37,4],[\"contents.common.interestTags.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,8],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"_allInterestTags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"if\",\"ui-content-info\",\"ui-info-item\",\"t\",\"ui-format-currency\",\"ac-contents/detail/wrapper\",\"ac-contents/detail/images\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/room-services/index/room-service.hbs",
    "isStrictMode": false
  });
});