define("additive-content/templates/instance/contents/portal-scores/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MxFqwRIu",
    "block": "[[[8,[39,0],null,[[\"@filterKey\",\"@modelName\",\"@nameProperty\",\"@descriptionProperty\",\"@detailRoute\",\"@hideActions\",\"@hideImage\"],[\"portal-scores\",\"portal-score\",\"title\",\"categoryNames\",\"instance.contents.portal-scores.index.portal-score\",[30,0,[\"isViewer\"]],true]],null],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"ui-list-view\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/portal-scores/index.hbs",
    "isStrictMode": false
  });
});