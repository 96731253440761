define("additive-content/serializers/survey", ["exports", "additive-content/serializers/contents", "lodash.clonedeep"], function (_exports, _contents, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SurveySerializer extends _contents.default {
    serialize(snapshot, options) {
      var _json$questions;
      const _json = super.serialize(snapshot, options);
      const json = (0, _lodash.default)(_json);
      json.questions = (_json$questions = json.questions) === null || _json$questions === void 0 ? void 0 : _json$questions.map(question => {
        question.id < 1 && delete question.id;
        return question;
      });
      return json;
    }
  }
  _exports.default = SurveySerializer;
});