define("additive-content/templates/instance/contents/rates/rates/rate", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QrMUf8xj",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@customEditRoute\",\"@customBackRoute\",\"@modelName\",\"@model\"],[\"rates\",\"instance.contents.rates.rates.rate.edit\",\"instance.contents.rates.rates\",\"rate\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@classNames\"],[\"mb2\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.rates.rate.properties.stays\"],null],[30,0,[\"stays\"]]]],null],[1,\"\\n      \"],[8,[39,2],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.rates.rate.properties.type\"],null],[28,[37,3],[[28,[37,4],[\"contents.rates.rate.properties.\",[30,0,[\"model\",\"type\"]]],null]],null]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[10,0],[14,0,\"mb1 font-sm secondary\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"contents.rates.rate.properties.mealTypes.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flex flex-wrap\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"model\",\"mealTypes\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"ph1 pv1 mr05 br1 bg-near-white\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"ph05 primary\"],[12],[1,\"\\n            \"],[1,[28,[35,3],[[28,[37,4],[\"contents.rates.rate.properties.mealTypes.\",[30,2]],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\",\"mealType\"],false,[\"ac-contents/detail\",\"ui-content-info\",\"ui-info-item\",\"t\",\"concat\",\"each\",\"-track-array\"]]",
    "moduleName": "additive-content/templates/instance/contents/rates/rates/rate.hbs",
    "isStrictMode": false
  });
});