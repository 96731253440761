define("additive-content/adapters/landing-page", ["exports", "additive-content/adapters/application", "@ember-data/adapter/error"], function (_exports, _application, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LandingPageAdapter extends _application.default {
    handleResponse(status, _, payload) {
      if (status === 409) {
        return new _error.default([{
          status,
          dependencies: payload.dependencies
        }]);
      }
      if (status === 403) {
        return new _error.default([{
          status,
          message: payload.message
        }]);
      }
      return super.handleResponse(...arguments);
    }
  }
  _exports.default = LandingPageAdapter;
});