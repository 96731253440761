define("additive-content/routes/instance/multimedia/index/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceMultimediaIndexRoute extends _route.default {
    redirect() {
      this.transitionTo('instance.multimedia.index.additive-multimedia-engine');
    }
  }
  _exports.default = InstanceMultimediaIndexRoute;
});