define("additive-content/routes/logout", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    session: (0, _service.inject)(),
    beforeModel() {
      this._super(...arguments);
      this.session && this.session.invalidate();
    }
  });
});