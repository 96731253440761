define("additive-content/utils/constants/widget-default-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getColorOptions = _exports.StyleValues = _exports.FONT_FALLBACKS = _exports.FAILURE_DEFAULT = _exports.DEFAULT_STYLES_TYPOGRAPHY = _exports.DEFAULT_STYLES_LAYOUT = _exports.DEFAULT_STYLES_COLORS = _exports.DEFAULT_STYLES = _exports.CONTAINER_VALUES = void 0;
  const DEFAULT_STYLES_COLORS = _exports.DEFAULT_STYLES_COLORS = {
    /* Main Colors */
    main: {
      color: '#3D3D3D',
      contrastColor: '#FFFFFF'
    },
    accent: {
      color: '#967E6E',
      contrastColor: '#FFFFFF'
    },
    ambient: {
      color: '#FFFFFF',
      contrastColor: '#3D3D3D'
    },
    /* Special colors */
    error: {
      color: '#F6374E',
      contrastColor: '#FFFFFF'
    }
  };
  const FAILURE_DEFAULT = _exports.FAILURE_DEFAULT = [{
    key: 'standard',
    value: {
      color: '#F6374E',
      contrastColor: '#FFFFFF'
    }
  }];
  const FONT_FALLBACKS = _exports.FONT_FALLBACKS = '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol';
  const DEFAULT_STYLES_TYPOGRAPHY = _exports.DEFAULT_STYLES_TYPOGRAPHY = {
    h1: {
      fontFamily: FONT_FALLBACKS,
      fontWeight: 'bold',
      fontSize: '40px',
      lineHeight: '48px',
      color: 'main'
    },
    h2: {
      fontFamily: 'primary',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '40px',
      color: 'main'
    },
    h3: {
      fontFamily: 'primary',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '28px',
      color: 'main'
    },
    p: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '24px'
    },
    label: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px'
    },
    button: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px'
    },
    small: {
      fontFamily: 'secondary',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px'
    }
  };
  const DEFAULT_STYLES = _exports.DEFAULT_STYLES = {
    borderRadius: '2px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
    scrollOnInit: false,
    scrollOffset: {
      sm: {
        value: '0',
        unit: 'px'
      },
      md: {
        value: '0',
        unit: 'px'
      }
    }
  };
  const DEFAULT_STYLES_LAYOUT = _exports.DEFAULT_STYLES_LAYOUT = {
    maxContentWidth: {
      value: '800',
      unit: 'px'
    },
    maxImageWidth: {
      value: '100',
      unit: '%'
    }
  };
  const CONTAINER_VALUES = _exports.CONTAINER_VALUES = {
    width: undefined,
    breakpointMd: 800,
    breakpointSm: 375
  };
  const StyleValues = _exports.StyleValues = {
    globals: DEFAULT_STYLES,
    colors: DEFAULT_STYLES_COLORS,
    container: CONTAINER_VALUES,
    typography: DEFAULT_STYLES_TYPOGRAPHY,
    layout: DEFAULT_STYLES_LAYOUT,
    fonts: {
      primary: FONT_FALLBACKS,
      secondary: FONT_FALLBACKS,
      fontWeightBold: 'bold'
    }
  };

  /**
   * Util that transforms given colors into an array which are supported by our select-component
   *
   *
   * The input param should always have the correct format as defined by the api.
   *
   * ```js
   * colors: {
   *  key: {
   *    main: {
   *      color: #000,
   *      contrastColor: #ff
   *    }
   *  }
   * }
   * ```
   * Available colors keys are `main`, `accent` and `ambient`.
   * Each one is again an object which consists of a color and a contrast color.
   *
   * @function getColorOptions
   * @param {Object} colors
   * @return {Array} colorOptions
   */
  const getColorOptions = colors => {
    const colorOptions = Object.keys(colors).map(key => {
      return {
        key,
        value: colors[key]
      };
    });
    return colorOptions;
  };
  _exports.getColorOptions = getColorOptions;
});