define("additive-content/mirage/routes/corporate-design", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CorporateDesign = void 0;
  const CorporateDesign = server => {
    server.get(`/corporate-design`, schema => {
      return schema.corporateDesigns.first();
    });
  };
  _exports.CorporateDesign = CorporateDesign;
});