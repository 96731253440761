define("additive-content/templates/instance/contents/posts/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dgt0UJwU",
    "block": "[[[8,[39,0],null,[[\"@modelName\",\"@nameProperty\",\"@descriptionProperty\",\"@detailRoute\",\"@hideImage\",\"@hideActions\",\"@filterKey\"],[\"post\",\"title\",\"subDescription\",\"instance.contents.posts.index.post\",true,[30,0,[\"isViewer\"]],\"posts\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"subTitle\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"allowComments\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"pl2 flex items-center \",[52,[30,2,[\"hasRecentComments\"]],\"blue\",\"secondary\"]]]],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"message\"],[[\"width\",\"height\"],[\"16\",\"16\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,0,[29,[\"pl1 font-sm font-small \",[52,[30,2,[\"hasRecentComments\"]],\"blue\",\"secondary\"]]]],[12],[1,\"\\n        \"],[1,[30,2,[\"commentsCount\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"menu\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@modelName\",\"@model\",\"@menuItem\",\"@editRoute\",\"@filterKey\"],[\"post\",[30,2],[30,1,[\"menuItem\"]],\"instance.contents.posts.index.post.edit\",\"posts\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\"],[46,[28,[37,5],null,null],null,null,null]],[\"list\",\"item\"],false,[\"ui-list-view\",\"if\",\"svg-jar\",\"ac-contents/actions\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/posts/index.hbs",
    "isStrictMode": false
  });
});