define("additive-content/routes/instance/contents/people/categories/category", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model(params) {
      return this.store.find('category', params.id);
    }
  });
});