define("additive-content/utils/constants", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOnBoarding = _exports.TOUR_TYPES = _exports.ROOM_TYPES = _exports.RATE_TYPES = _exports.PRICE_INFORMATION = _exports.MEAL_TYPES = _exports.LABEL_SYMBOL_TYPES = _exports.CONTENT_TYPE_ICONS = _exports.AVAILABLE_LANGUAGES = void 0;
  const CONTENT_TYPE_ICONS = _exports.CONTENT_TYPE_ICONS = {
    treatment: 'wellness',
    tour: 'hiking',
    webcam: 'webcam',
    person: 'group',
    daily_menu: 'food',
    hotel_info: 'info',
    event: 'calendar',
    rate: 'rate',
    room_service: 'stay',
    room: 'room',
    offer: 'offer',
    last_minute_offer: 'last-minute',
    page: 'page',
    product: 'product',
    frequently_asked_question: 'help',
    gallery: 'gallery',
    quote: 'quote',
    point_of_interest: 'pin',
    survey: 'emoji',
    portal_score: 'portal-score',
    post: 'feather'
  };
  const TOUR_TYPES = _exports.TOUR_TYPES = ['bike', 'climbing', 'climbing_route', 'hike', 'mountainbike', 'nordic_walking', 'ski_tour', 'snowshoeing', 'summit_tour', 'walk'];
  const ROOM_TYPES = _exports.ROOM_TYPES = ['single', 'double', 'suite', 'juniorSuite', 'apartment', 'chalet', 'family'];
  const AVAILABLE_LANGUAGES = _exports.AVAILABLE_LANGUAGES = ['de', 'it', 'en', 'fr', 'nl', 'pl'];
  const LABEL_SYMBOL_TYPES = _exports.LABEL_SYMBOL_TYPES = ['flame', 'heart'];
  const RATE_TYPES = _exports.RATE_TYPES = ['per_person', 'per_room'];
  const MEAL_TYPES = _exports.MEAL_TYPES = ['no_service', 'breakfast', 'half_board', 'full_board', 'all_inclusive'];
  const PRICE_INFORMATION = _exports.PRICE_INFORMATION = ['daily_per_person', 'daily_per_room', 'daily', 'bundle_per_person', 'bundle_per_room', 'bundle'];
  const getOnBoarding = intl => {
    return {
      '1': [{
        title: intl.t('onboarding.1.title'),
        description: (0, _string.htmlSafe)(intl.t('onboarding.1.description')),
        illustration: 'content-logo',
        isFirstStep: true
      }, {
        title: intl.t('onboarding.2.title'),
        description: (0, _string.htmlSafe)(intl.t('onboarding.2.description')),
        illustration: 'cycle',
        theme: 'dark',
        isLastStep: true
      }]
    };
  };
  _exports.getOnBoarding = getOnBoarding;
});