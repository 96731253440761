define("additive-content/validations/webcam", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.webcamValidation = void 0;
  const webcamValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const urlMessage = intl.t('errors.url');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      url: (0, _validators.validateFormat)({
        type: 'url',
        message: urlMessage
      })
    };
  };
  _exports.webcamValidation = webcamValidation;
});