define("additive-content/transforms/iso-date", ["exports", "@ember-data/serializer/transform", "date-fns"], function (_exports, _transform, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Transform that parses and serializes a date from a in iso format
   */
  class IsoDateTransform extends _transform.default {
    deserialize(serialized) {
      return serialized && (0, _dateFns.parseISO)(serialized);
    }
    serialize(deserialized, _ref) {
      let {
        dateFormat = 'yyyy-MM-dd'
      } = _ref;
      return deserialized && (0, _dateFns.format)(deserialized, dateFormat);
    }
  }
  _exports.default = IsoDateTransform;
});