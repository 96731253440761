define("additive-content/mirage/config", ["exports", "additive-content/config/environment", "additive-content/mirage/routes/content-types", "additive-content/mirage/routes/categories", "additive-content/mirage/routes/portal-scores", "additive-content/mirage/routes/corporate-design", "additive-content/mirage/routes/landing-pages", "additive-content/mirage/routes/render-service", "additive-content/mirage/routes/tags", "additive-content/mirage/routes/settings", "additive-content/mirage/routes/styles"], function (_exports, _environment, _contentTypes, _categories, _portalScores, _corporateDesign, _landingPages, _renderService, _tags, _settings, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.urlPrefix = _environment.default.APP.apiBaseHost;
    /* Allow any organization-id */
    this.namespace = '**';

    // disable mirage routes for which the API exists
    if (_environment.default.environment === 'test') {
      (0, _categories.Categories)(this);
      (0, _portalScores.PortalScores)(this);
      (0, _contentTypes.ContentTypes)(this);
      (0, _contentTypes.Timespans)(this);
      (0, _tags.Tags)(this);
      (0, _settings.Settings)(this);
      (0, _corporateDesign.CorporateDesign)(this);
      (0, _styles.Styles)(this);
      (0, _landingPages.LandingPages)(this);
      (0, _renderService.RenderService)(this);
    }
  }
});