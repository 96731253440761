define("additive-content/templates/instance/contents/last-minute-offers", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "bX2e9Ff7",
    "block": "[[[8,[39,0],null,[[\"@filterName\",\"@modelName\",\"@contentsRoute\",\"@linkToCreateView\"],[\"last-minute-offers\",\"last-minute-offer\",\"instance.contents.last-minute-offers.index\",\"instance.contents.last-minute-offers.index.create\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbarRight\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"nav\",\"action\"]],null,[[\"@icon\",\"@variant\",\"@label\",\"@hideText\",\"@onClick\"],[\"settings\",[30,1,[\"nav\",\"actionVariant\"]],[28,[37,1],[\"global.actions.configure\"],null],[28,[37,2],[[30,1,[\"nav\",\"actionSizes\"]],0],null],[30,0,[\"toggleDrawer\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[8,[39,5],null,[[\"@stateKey\",\"@isReadOnly\"],[[30,0,[\"stateKey\"]],[30,0,[\"settingsController\",\"isReadOnly\"]]]],null],[1,\"\\n\"],[46,[28,[37,4],[\"detail\"],null],null,null,null],[1,\"\\n\"]],[\"contentView\"],false,[\"ac-contents/main-view\",\"t\",\"get\",\"component\",\"-outlet\",\"ac-contents/last-minute-offers/settings-navdrawer\"]]",
    "moduleName": "additive-content/templates/instance/contents/last-minute-offers.hbs",
    "isStrictMode": false
  });
});