define("additive-content/mirage/factories/category", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    name: () => _faker.default.company.catchPhraseNoun(),
    language: 'de',
    availableLanguages: () => ['de'],
    hotelInfo: (0, _emberCliMirage.trait)({
      categorizableType: 'hotel_info'
    }),
    treatment: (0, _emberCliMirage.trait)({
      categorizableType: 'treatment'
    }),
    frequently_asked_question: (0, _emberCliMirage.trait)({
      categorizableType: 'frequently_asked_question'
    }),
    point_of_interest: (0, _emberCliMirage.trait)({
      categorizableType: 'point_of_interest'
    }),
    gallery: (0, _emberCliMirage.trait)({
      categorizableType: 'gallery'
    }),
    offer: (0, _emberCliMirage.trait)({
      categorizableType: 'offer'
    }),
    page: (0, _emberCliMirage.trait)({
      categorizableType: 'page'
    }),
    person: (0, _emberCliMirage.trait)({
      categorizableType: 'person'
    }),
    product: (0, _emberCliMirage.trait)({
      categorizableType: 'product'
    }),
    quote: (0, _emberCliMirage.trait)({
      categorizableType: 'quote'
    }),
    portal_score: (0, _emberCliMirage.trait)({
      categorizableType: 'portal_score'
    }),
    room_service: (0, _emberCliMirage.trait)({
      categorizableType: 'room_service'
    }),
    room: (0, _emberCliMirage.trait)({
      categorizableType: 'room'
    }),
    survey: (0, _emberCliMirage.trait)({
      categorizableType: 'survey'
    }),
    tour: (0, _emberCliMirage.trait)({
      categorizableType: 'tour'
    }),
    webcam: (0, _emberCliMirage.trait)({
      categorizableType: 'webcam'
    })
  });
});