define("additive-content/instance-initializers/full-story", ["exports", "@additive-apps/jam-ember/instance-initializers/full-story"], function (_exports, _fullStory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fullStory.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _fullStory.initialize;
    }
  });
});