define("additive-content/serializers/landing-page-block", ["exports", "additive-content/serializers/application", "lodash.clonedeep"], function (_exports, _application, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LandingPageSerializer extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const blocks = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];
      blocks.forEach((block, blockIndex) => {
        var _block$content;
        (_block$content = block.content) === null || _block$content === void 0 || _block$content.forEach((content, index) => {
          var _content$properties;
          (_content$properties = content.properties) === null || _content$properties === void 0 || _content$properties.forEach((property, propertyIndex) => {
            if (['faq_categories', 'room_categories', 'offer_categories', 'last_minute_offer_categories', 'quote_categories', 'usp_categories'].includes(property.id)) {
              blocks[blockIndex].content[index].properties[propertyIndex].value = Object.assign({
                content: [],
                categories: [],
                categoryIds: []
              }, property.value);
            }
            if (property.id === 'accommodations' && Array.isArray(property.value)) {
              property.value.forEach((accommodation, accommodationIndex) => {
                if (Array.isArray(accommodation)) {
                  blocks[blockIndex].content[index].properties[propertyIndex].value[accommodationIndex] = {};
                }
              });
            }
          });
        });
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    serialize(snapshot, options) {
      var _jsonCopy$content, _jsonCopy$style;
      let json = super.serialize(snapshot, options);
      const jsonCopy = (0, _lodash.default)(json);
      (_jsonCopy$content = jsonCopy.content) === null || _jsonCopy$content === void 0 || _jsonCopy$content.forEach((content, index) => {
        var _content$properties2;
        (_content$properties2 = content.properties) === null || _content$properties2 === void 0 || _content$properties2.forEach((property, propertyIndex) => {
          if (['faq_categories', 'room_categories', 'offer_categories', 'last_minute_offer_categories', 'quote_categories', 'usp_categories'].includes(property.id)) {
            if (jsonCopy.content[index].properties[propertyIndex].value) {
              delete jsonCopy.content[index].properties[propertyIndex].value.content;
              delete jsonCopy.content[index].properties[propertyIndex].value.categories;
            }
          } else if (property.type === 'select') {
            if (Array.isArray(property.value) && property.value.length === 0) {
              jsonCopy.content[index].properties[propertyIndex].value = null;
            }
          }
        });
      });
      (_jsonCopy$style = jsonCopy.style) === null || _jsonCopy$style === void 0 || _jsonCopy$style.forEach((style, index) => {
        var _style$properties;
        (_style$properties = style.properties) === null || _style$properties === void 0 || _style$properties.forEach((property, propertyIndex) => {
          if (property.type === 'select') {
            if (Array.isArray(property.value) && property.value.length === 0) {
              jsonCopy.style[index].properties[propertyIndex].value = null;
            }
          }
        });
      });
      return jsonCopy;
    }
  }
  _exports.default = LandingPageSerializer;
});