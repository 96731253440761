define("additive-content/mirage/routes/content-types", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Timespans = _exports.ContentTypes = void 0;
  const ContentTypes = server => {
    server.get('/:contentType', (schema, _ref) => {
      let {
        params: {
          contentType
        }
      } = _ref;
      return schema[(0, _string.camelize)(contentType)].all();
    });
    server.get('/:contentType/:id', (schema, _ref2) => {
      let {
        params: {
          id,
          contentType
        }
      } = _ref2;
      return schema[(0, _string.camelize)(contentType)].find(id);
    });
  };
  _exports.ContentTypes = ContentTypes;
  const Timespans = server => {
    server.get('/offers/:id/timespans', (schema, _ref3) => {
      let {
        params: {
          id
        }
      } = _ref3;
      return schema.timespans.all().filter(timespan => {
        var _timespan$offer;
        return ((_timespan$offer = timespan.offer) === null || _timespan$offer === void 0 ? void 0 : _timespan$offer.id) === id;
      });
    });
  };
  _exports.Timespans = Timespans;
});