define("additive-content/routes/instance/contents/points-of-interest/categories/category", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsPointsOfInterestCategoriesCategoryRoute extends _route.default {
    model(params) {
      return this.store.find('category', params.id);
    }
  }
  _exports.default = InstanceContentsPointsOfInterestCategoriesCategoryRoute;
});