define("additive-content/helpers/filter-default-landing-pages", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: change to local Helper in acLandingPages::List in Ember >= 4.5
  class FilterDefaultLandingPages extends _helper.default {
    compute(_ref) {
      let [array] = _ref;
      return array.filter(item => item.type === 'default');
    }
  }
  _exports.default = FilterDefaultLandingPages;
});