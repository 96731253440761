define("additive-content/validations/rate", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rateValidation = void 0;
  const rateValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxLength: 200
    });
    const maxStaysMessage = intl.t('errors.maxStays');
    const greaterZeroMessage = intl.t('errors.gtZero');
    return {
      name: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateLength)({
        max: 200,
        message: maxLengthMessage
      })],
      mealTypes: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      type: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      minStays: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        message: greaterZeroMessage
      })],
      maxStays: (0, _numeral.validateNumeral)({
        gte: 'minStays',
        allowBlank: true,
        message: maxStaysMessage
      })
    };
  };
  _exports.rateValidation = rateValidation;
});