define("additive-content/serializers/tour", ["exports", "additive-content/serializers/contents"], function (_exports, _contents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _contents.default.extend({
    serialize(snapshot, options) {
      var _json$interests, _json$travelTimes, _json$travelMotivatio;
      const json = this._super(snapshot, options);

      // transform interests to string array
      json.interests = (_json$interests = json.interests) === null || _json$interests === void 0 ? void 0 : _json$interests.map(interest => interest.id ? interest.id : interest);
      json.travelTimes = (_json$travelTimes = json.travelTimes) === null || _json$travelTimes === void 0 ? void 0 : _json$travelTimes.map(travelTime => travelTime.id ? travelTime.id : travelTime);
      json.travelMotivations = (_json$travelMotivatio = json.travelMotivations) === null || _json$travelMotivatio === void 0 ? void 0 : _json$travelMotivatio.map(travelMotivation => travelMotivation.id ? travelMotivation.id : travelMotivation);
      return json;
    }
  });
});