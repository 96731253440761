define("additive-content/adapters/application", ["exports", "additive-content/config/environment", "@additive-apps/auth/adapters/application", "@ember-data/adapter/error", "ember-inflector", "@ember/service", "@ember/object"], function (_exports, _environment, _application, _error, _emberInflector, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    currentUser: (0, _service.inject)(),
    uiAppSettings: (0, _service.inject)(),
    uiDialog: (0, _service.inject)(),
    host: _environment.default.APP.apiBaseHost,
    currentLocale: _object.computed.alias('uiAppSettings.currentLocale'),
    headers: (0, _object.computed)('currentLocale', 'session.data.authenticated.access_token', {
      get() {
        const headers = {
          Accept: 'application/vnd.additive+json; version=2',
          'Accept-Language': this.currentLocale || 'de',
          'Content-Type': 'application/json'
        };
        return Object.assign({}, this._super('headers'), headers);
      }
    }),
    namespace: (0, _object.computed)('currentUser.currentOrganization.id', {
      get() {
        return this.currentUser.currentOrganization.id;
      }
    }),
    pathForType(modelName) {
      return (0, _emberInflector.pluralize)(modelName);
    },
    shouldReloadAll() {
      return true;
    },
    handleResponse(status, _, payload) {
      if (status === 409) {
        return new _error.default([{
          status: status,
          detail: 'used_in_post',
          dependencies: payload.dependencies
        }]);
      }
      if (status === 422) {
        var _Object$keys;
        const errors = payload.errors || {};
        const firstErrorKey = ((_Object$keys = Object.keys(errors)) === null || _Object$keys === void 0 ? void 0 : _Object$keys[0]) || null;
        return new _error.default([{
          status: status,
          detail: firstErrorKey ? payload === null || payload === void 0 ? void 0 : payload.errors[firstErrorKey][0] : null,
          payload: payload
        }]);
      }
      return this._super(...arguments);
    }
  });
});