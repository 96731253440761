define("additive-content/utils/slug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringToSlug = void 0;
  /**
   * Convert string to a slug.
   * All characters are removed except letters, numbers and dashes
   * All spaces are replaced with dashes.
   *
   * @param {String} string
   */
  const stringToSlug = string => {
    let slug = string;
    if (!slug) {
      return '';
    }

    // Remove all characters except letters, numbers and dashes
    slug = slug.replace(/\s/g, '-'); // Replace spaces with dashes
    slug = slug.replace(/ä/g, 'ae'); // replace umlaute
    slug = slug.replace(/ö/g, 'oe');
    slug = slug.replace(/ü/g, 'ue');
    slug = slug.replace(/ß/g, 'ss');
    slug = slug.replace(/[^a-zA-Z0-9-]/g, '');
    slug = slug.replace(/-+/g, '-'); // Replace multiple dashes
    slug = slug.replace(/^-+/g, ''); // Remove dash at beginning
    slug = slug.replace(/-+$/g, ''); // Remove dash at ending

    return slug.toLowerCase();
  };
  _exports.stringToSlug = stringToSlug;
});