define("additive-content/templates/instance/contents/surveys/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "fTWUu59f",
    "block": "[[[8,[39,0],null,[[\"@filterKey\",\"@modelName\",\"@descriptionProperty\",\"@detailRoute\",\"@hideImage\",\"@hideActions\"],[\"surveys\",\"survey\",\"interestsCountDescription\",\"instance.contents.surveys.index.survey\",true,[30,0,[\"isViewer\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"menu\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filterKey\",\"@modelName\",\"@model\",\"@menuItem\",\"@editRoute\"],[\"surveys\",\"survey\",[30,2],[30,1,[\"menuItem\"]],\"instance.contents.surveys.index.survey.edit\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[\"list\",\"item\"],false,[\"ui-list-view\",\"ac-contents/actions\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/surveys/index.hbs",
    "isStrictMode": false
  });
});