define("additive-content/mirage/scenarios/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.partnerMember = _exports.partnerManager = _exports.partnerAdmin = _exports.member = _exports.manager = _exports.admin = _exports.additiveAdmin = void 0;
  const partnerMember = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'member'
      });
    });
  };
  _exports.partnerMember = partnerMember;
  const partnerManager = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'manager'
      });
    });
  };
  _exports.partnerManager = partnerManager;
  const partnerAdmin = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isPartner: true,
        role: 'admin'
      });
    });
  };
  _exports.partnerAdmin = partnerAdmin;
  const member = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isAdditive: false,
        isPartner: false,
        role: 'member'
      });
    });
  };
  _exports.member = member;
  const manager = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isAdditive: false,
        isPartner: false,
        role: 'manager'
      });
    });
  };
  _exports.manager = manager;
  const admin = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isAdditive: false,
        isPartner: false,
        role: 'admin'
      });
    });
  };
  _exports.admin = admin;
  const additiveAdmin = server => {
    server.get(`/me`, schema => {
      return schema.users.findBy({
        isAdditive: true,
        isPartner: true,
        role: 'admin'
      });
    });
  };
  _exports.additiveAdmin = additiveAdmin;
});