define("additive-content/helpers/ui-format-currency", ["exports", "@additive-apps/ui/helpers/ui-format-currency"], function (_exports, _uiFormatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiFormatCurrency.default;
    }
  });
  Object.defineProperty(_exports, "uiFormatCurrency", {
    enumerable: true,
    get: function get() {
      return _uiFormatCurrency.uiFormatCurrency;
    }
  });
});