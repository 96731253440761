define("additive-content/services/current-user", ["exports", "additive-content/config/environment", "@additive-apps/auth/services/current-user"], function (_exports, _environment, _currentUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ENV variables from the application are passed
   * to the service and can be used in the addon.
   */
  var _default = _exports.default = _currentUser.default.extend({
    ENV: _environment.default
  });
});