define("additive-content/mirage/routes/interests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Interests = void 0;
  const Interests = server => {
    server.get('/interests', schema => {
      return schema.interests.all();
    });
  };
  _exports.Interests = Interests;
});