define("additive-content/utils/landing-page-utils", ["exports", "ember-concurrency", "@ember/object"], function (_exports, _emberConcurrency, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deleteLandingPage = void 0;
  const deleteLandingPage = _exports.deleteLandingPage = {};

  /**
   * deletes the active landing page
   *
   * @function deleteLandingPageTask
   * @type {Task}
   */
  const deleteLandingPageTask = (0, _emberConcurrency.task)(function* (self) {
    try {
      const modelId = self.model.id;
      yield self.model.destroyRecord();
      if (self.router.isActive(self.router.currentRouteName, modelId)) {
        self.router.transitionTo('instance.landing-pages');
      }
      yield self.uiFilter.reset('landing-pages');
      self.uiToast.showToast({
        type: 'success',
        title: self.intl.t('toast.success'),
        duration: 2000
      });
    } catch (error) {
      var _error$errors;
      if ((error === null || error === void 0 || (_error$errors = error.errors) === null || _error$errors === void 0 ? void 0 : _error$errors.length) > 0) {
        const errorData = error.errors[0];
        // handle conflict error
        if (errorData.status === 409) {
          var _Object$keys;
          const {
            intl
          } = self;
          (_Object$keys = Object.keys(errorData.dependencies)) === null || _Object$keys === void 0 || _Object$keys.forEach(appId => {
            var _errorData$dependenci;
            (_errorData$dependenci = errorData.dependencies[appId].settings) === null || _errorData$dependenci === void 0 || _errorData$dependenci.forEach((dependency, index) => {
              delete errorData.dependencies[appId].settings[index].id;
            });
          });
          self.uiDialog.showConflictError(intl.t('components.acLandingPages.actions.deleteDialog.errors.conflict.title'), intl.t('components.acLandingPages.actions.deleteDialog.errors.conflict.description'), errorData.dependencies);
          return;
        }
      }
      self.uiToast.showToast({
        type: 'error',
        title: self.intl.t('toast.unexpectedError'),
        duration: 2000
      });
    } finally {
      self._isConfirmDeleteDialog = false;
    }
  });
  (0, _object.defineProperty)(deleteLandingPage, 'task', deleteLandingPageTask);
});