define("additive-content/helpers/array-concat", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.arrayConcat = void 0;
  const arrayConcat = params => {
    const [...elements] = params;
    let array = [];
    elements.forEach(element => {
      if (Array.isArray(element)) {
        array = array.concat(element);
      } else {
        element && array.push(element);
      }
    });
    return array;
  };
  _exports.arrayConcat = arrayConcat;
  var _default = _exports.default = (0, _helper.helper)(arrayConcat);
});