define("additive-content/validations/season", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.seasonValidation = _exports.seasonRoomValidation = void 0;
  const seasonValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      startsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      endsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      rate: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.seasonValidation = seasonValidation;
  const seasonRoomValidation = (intl, mealTypes) => {
    const numberMessage = intl.t('errors.gteZero');
    const validation = {};
    mealTypes.forEach(type => {
      validation[type] = [(0, _numeral.validateNumeral)({
        gte: 0,
        allowBlank: true,
        message: numberMessage
      })];
    });
    return validation;
  };
  _exports.seasonRoomValidation = seasonRoomValidation;
});