define("additive-content/components/ui-card", ["exports", "@additive-apps/ui/components/ui-card/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _index.default;
    }
  });
});