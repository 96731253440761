define("additive-content/controllers/page", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Page extends _controller.default.extend({
    // anything which *must* be merged to prototype here
  }) {
    // normal class body definition here
  }

  // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
  _exports.default = Page;
});