define("additive-content/utils/chart-tooltip-plugin", ["exports", "@additive-apps/ui/utils/chart-tooltip-plugin"], function (_exports, _chartTooltipPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _chartTooltipPlugin.default;
    }
  });
});