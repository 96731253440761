define("additive-content/helpers/html-safe", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlSafe = _exports.default = void 0;
  const htmlSafe = _ref => {
    let [string] = _ref;
    return (0, _string.htmlSafe)(string);
  };
  _exports.htmlSafe = htmlSafe;
  var _default = _exports.default = (0, _helper.helper)(htmlSafe);
});