define("additive-content/adapters/style-block", ["exports", "additive-content/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StyleBlockAdapter extends _application.default {
    urlForMultiple(_, snapshot) {
      var _snapshot$adapterOpti;
      let url = this.buildURL();
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.style) {
        const style = snapshot.adapterOptions.style;
        url += `/styles/${style}/blocks`;
      }
      return url;
    }
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    }
    urlForFindRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForFindAll() {
      return this.urlForMultiple(...arguments);
    }
    urlForCreateRecord() {
      return this.urlForMultiple(...arguments);
    }
    urlForUpdateRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForDeleteRecord() {
      return this.urlForSingle(...arguments);
    }
  }
  _exports.default = StyleBlockAdapter;
});