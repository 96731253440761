define("additive-content/templates/instance/contents/portal-scores/index/portal-score/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "hAPOnxYA",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@validation\",\"@isReadOnly\",\"@categorizableType\",\"@modelName\",\"@contentTypeRoute\"],[[30,0,[\"model\"]],[30,0,[\"validation\"]],[30,0,[\"isViewer\"]],\"portal_score\",\"portal-score\",\"portal-scores\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"font-lg black pb2 mt3\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"contents.portalScores.portalScore.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"loadTypes\",\"isRunning\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@items\",\"@itemsPerRow\",\"@cardHeight\"],[1,1,90]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],[[16,\"data-test-portal-score-select-type\",true]],[[\"@label\",\"@selected\",\"@isReadOnly\",\"@errorMessage\"],[[28,[37,1],[\"contents.portalScores.portalScore.properties.type\"],null],[30,1,[\"changeset\",\"name\"]],true,[28,[37,5],[[30,1,[\"changeset\",\"error\",\"type\"]],\"validation\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[8,[39,6],[[16,\"data-test-portal-score-input-score\",true]],[[\"@label\",\"@value\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\",\"@type\",\"@theme\"],[[28,[37,1],[\"contents.portalScores.portalScore.properties.score\"],null],[30,1,[\"changeset\",\"score\"]],[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"score\",\"validation\"]],[30,1,[\"isTouched\"]],\"number\",\"light\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n        /\"],[1,[30,1,[\"changeset\",\"scoreMax\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"detail\"]],null,null,null],[1,\"\\n\"]],[1]]]]]],[\"contentView\",\"input\"],false,[\"ac-contents/edit\",\"t\",\"if\",\"ui-skeleton\",\"ui-select\",\"get\",\"ui-input\"]]",
    "moduleName": "additive-content/templates/instance/contents/portal-scores/index/portal-score/edit.hbs",
    "isStrictMode": false
  });
});