define("additive-content/templates/instance/contents/rates/index/season", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "pW/bwGZQ",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@subtitleProperty\",\"@model\"],[\"rates\",\"season\",\"rate.name\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@season\"],[[30,0,[\"model\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[\"Detail\"],false,[\"ac-contents/detail\",\"ac-contents/rates/prices-table\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/rates/index/season.hbs",
    "isStrictMode": false
  });
});