define("additive-content/adapters/style", ["exports", "@ember-data/adapter/error", "additive-content/adapters/application"], function (_exports, _error, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WidgetAdapter extends _application.default {
    handleResponse(status, _, payload) {
      if (status === 403) {
        return new _error.default([{
          status: status,
          code: payload.code,
          detail: payload.message
        }]);
      }
      return super.handleResponse(...arguments);
    }
    urlForUpdateRecord(id, _, snapshot) {
      var _snapshot$adapterOpti;
      const baseUrl = `${this.buildURL()}/styles/${id}`;
      if ((_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.isPreview) {
        return `${baseUrl}/blocks-preview`;
      }
      return baseUrl;
    }
  }
  _exports.default = WidgetAdapter;
});