define("additive-content/components/ac-content-types/card", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "additive-content/utils/constants", "@ember/service", "@ember/object", "ember-arg-types", "prop-types", "ember-inflector"], function (_exports, _component, _templateFactory, _component2, _constants, _service, _object, _emberArgTypes, _propTypes, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ac-content-item flex items-center pa1 br1 bg-white cpointer"
    role="link"
    {{on "click" this.onClick}}
  >
    <div class="flex items-center">
      {{svg-jar (get this.icons this.content.type) width="20" height="20"}}
    </div>
    <div class="ac-content-item__content ellipsis flex items-center font-sm primary">
      {{t (concat (concat "contents.contentTypes." this.content.type) ".title")}}
    </div>
    <span class="flex-grow-1"></span>
    <div class="pa05">
      <span class="ac-content-item__count text-center bg-secondary--10 secondary font-xs ph05 br1">
        {{this.content.count}}
      </span>
    </div>
  </div>
  */
  {
    "id": "8/I/1wrG",
    "block": "[[[11,0],[24,0,\"ac-content-item flex items-center pa1 br1 bg-white cpointer\"],[24,\"role\",\"link\"],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"icons\"]],[30,0,[\"content\",\"type\"]]],null]],[[\"width\",\"height\"],[\"20\",\"20\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ac-content-item__content ellipsis flex items-center font-sm primary\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[28,[37,4],[[28,[37,4],[\"contents.contentTypes.\",[30,0,[\"content\",\"type\"]]],null],\".title\"],null]],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"flex-grow-1\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"pa05\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ac-content-item__count text-center bg-secondary--10 secondary font-xs ph05 br1\"],[12],[1,\"\\n      \"],[1,[30,0,[\"content\",\"count\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"on\",\"svg-jar\",\"get\",\"t\",\"concat\"]]",
    "moduleName": "additive-content/components/ac-content-types/card.hbs",
    "isStrictMode": false
  });
  const CUSTOM_ROUTES = {
    point_of_interest: 'points-of-interest'
  };

  /**
   * A card displaying the content type
   *
   * @class ac-content-types/card
   */
  let AcContentTypesCard = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), (_class = class AcContentTypesCard extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * the content type model
       *
       * @argument content
       * @type {Model}
       * @default null
       */
      _initializerDefineProperty(this, "content", _descriptor2, this);
      /**
       * object containing the svgs for every content-type
       *
       * @property icons
       * @type {Object}
       * @default CONTENT_TYPE_ICONS
       */
      _defineProperty(this, "icons", _constants.CONTENT_TYPE_ICONS);
    }
    onClick() {
      var _this$content, _this$content2;
      const contentName = CUSTOM_ROUTES[(_this$content = this.content) === null || _this$content === void 0 ? void 0 : _this$content.type] || (0, _emberInflector.pluralize)((_this$content2 = this.content) === null || _this$content2 === void 0 ? void 0 : _this$content2.type.split('_').join('-'));
      this.router.transitionTo(`instance.contents.${contentName}`);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "content", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentTypesCard);
});