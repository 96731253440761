define("additive-content/validations/offer", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.offerValidation = void 0;
  const offerValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const integerMessage = intl.t('errors.integer');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxLength: 40
    });
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      description: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      includedServices: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      stays: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      priceInformation: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      slug: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      label: (0, _validators.validateLength)({
        max: 40,
        message: maxLengthMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.offerValidation = offerValidation;
});