define("additive-content/serializers/daily-menu", ["exports", "additive-content/serializers/contents", "date-fns/format"], function (_exports, _contents, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _contents.default.extend({
    serialize(snapshot, options) {
      const json = this._super(snapshot, options);
      json.date = (json === null || json === void 0 ? void 0 : json.date) && (0, _format.default)(new Date(json.date), 'yyyy-MM-dd');
      return json;
    }
  });
});