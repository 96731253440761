define("additive-content/mirage/scenarios/app/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * server is the default scenario for normal app-use (development, staging)
   */
  function _default(server) {
    server.passthrough('https://staging.account.additive-apps.tech/**');
    server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
    server.passthrough('https://api.staging.voucher.additive-apps.tech/**');
    server.passthrough('https://staging.render.content.additive-apps.tech/**');
    server.passthrough('https://staging.content.additive-apps.tech/css/landing-page.css');
    server.passthrough('https://helper.additive-apps.tech/**');
    server.passthrough(`${server.urlPrefix}/${server.namespace}/`);
  }
});