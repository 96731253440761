define("additive-content/components/ac-sync-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiPopover as |pop|>
    <pop.trigger>
      {{#if @isReadOnly}}
        {{svg-jar
          "dna"
          class=(concat (if @isSyncing "blue" "secondary") " " @class)
          width="20"
          height="20"
        }}
      {{else}}
        <UiIconButton
          @class={{@class}}
          @icon="dna"
          @isTertiary={{not @isSyncing}}
          @onClick={{@onClick}}
        />
      {{/if}}
    </pop.trigger>
    <pop.content>
      {{t "global.help.inheritanceDescription"}}
    </pop.content>
  </UiPopover>
  */
  {
    "id": "6HZ0Q2vq",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,2],[[[1,\"      \"],[1,[28,[35,2],[\"dna\"],[[\"class\",\"width\",\"height\"],[[28,[37,3],[[52,[30,3],\"blue\",\"secondary\"],\" \",[30,4]],null],\"20\",\"20\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,4],null,[[\"@class\",\"@icon\",\"@isTertiary\",\"@onClick\"],[[30,4],\"dna\",[28,[37,5],[[30,3]],null],[30,5]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,6],[\"global.help.inheritanceDescription\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"pop\",\"@isReadOnly\",\"@isSyncing\",\"@class\",\"@onClick\"],false,[\"ui-popover\",\"if\",\"svg-jar\",\"concat\",\"ui-icon-button\",\"not\",\"t\"]]",
    "moduleName": "additive-content/components/ac-sync-button.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});