define("additive-content/adapters/comment", ["exports", "additive-content/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CommentAdapter extends _application.default {
    urlForMultiple(_, snapshot) {
      var _snapshot$adapterOpti, _snapshot$adapterOpti2;
      let url = this.buildURL();
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.postId) {
        url += `/posts/${snapshot.adapterOptions.postId}/comments`;
      }
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.commentId) {
        url += `/${snapshot.adapterOptions.commentId}`;
      }
      return url;
    }
    urlForSingle(id, modelName, snapshot) {
      return this.urlForMultiple(modelName, snapshot);
    }
    urlForFindAll() {
      return this.urlForMultiple(...arguments);
    }
    urlForFindRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForCreateRecord() {
      return this.urlForMultiple(...arguments);
    }
    urlForUpdateRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForDeleteRecord() {
      return this.urlForSingle(...arguments);
    }
  }
  _exports.default = CommentAdapter;
});