define("additive-content/templates/instance/contents/rates/index/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "H0zlUmcj",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null]],[],false,[\"ac-contents/rates/season-dialog\"]]",
    "moduleName": "additive-content/templates/instance/contents/rates/index/create.hbs",
    "isStrictMode": false
  });
});