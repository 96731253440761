define("additive-content/serializers/event", ["exports", "additive-content/serializers/contents", "date-fns/format", "date-fns/parse"], function (_exports, _contents, _format, _parse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _contents.default.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];
      contents.forEach(content => {
        if (content.timeStart) {
          content.timeStart = content.timeStart.length > 5 ? (0, _parse.default)(content.timeStart, 'HH:mm:ss', new Date()) : (0, _parse.default)(content.timeStart, 'HH:mm', new Date());
        }
        if (content.timeEnd) {
          content.timeEnd = content.timeEnd.length > 5 ? (0, _parse.default)(content.timeEnd, 'HH:mm:ss', new Date()) : (0, _parse.default)(content.timeEnd, 'HH:mm', new Date());
        }
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize(snapshot, options) {
      var _json$interests, _json$travelTimes, _json$travelMotivatio;
      const json = this._super(snapshot, options);
      json.date = (json === null || json === void 0 ? void 0 : json.date) && (0, _format.default)(new Date(json.date), 'yyyy-MM-dd');
      json.startsAt = (json === null || json === void 0 ? void 0 : json.startsAt) && (0, _format.default)(new Date(json.startsAt), 'yyyy-MM-dd');
      json.endsAt = (json === null || json === void 0 ? void 0 : json.endsAt) && (0, _format.default)(new Date(json.endsAt), 'yyyy-MM-dd');
      json.timeStart = (json === null || json === void 0 ? void 0 : json.timeStart) && (0, _format.default)(new Date(json.timeStart), 'HH:mm');
      json.timeEnd = (json === null || json === void 0 ? void 0 : json.timeEnd) && (0, _format.default)(new Date(json.timeEnd), 'HH:mm');
      if (json.rrule && !json.rrule.freq) {
        json.rrule = null;
      }

      // transform interests to string array
      json.interests = (_json$interests = json.interests) === null || _json$interests === void 0 ? void 0 : _json$interests.map(interest => interest.id ? interest.id : interest);
      json.travelTimes = (_json$travelTimes = json.travelTimes) === null || _json$travelTimes === void 0 ? void 0 : _json$travelTimes.map(travelTime => travelTime.id ? travelTime.id : travelTime);
      json.travelMotivations = (_json$travelMotivatio = json.travelMotivations) === null || _json$travelMotivatio === void 0 ? void 0 : _json$travelMotivatio.map(travelMotivation => travelMotivation.id ? travelMotivation.id : travelMotivation);
      return json;
    }
  });
});