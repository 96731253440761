define("additive-content/templates/instance/styles/style/block/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "xCaZ/wof",
    "block": "[[[10,0],[14,0,\"flex justify-between items-center font-lg mb3\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"pv05\"],[12],[1,\"\\n    \"],[1,[30,0,[\"model\",\"block\",\"name\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\",\"variations\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"instance.styles.style.block.variation\",[30,1,[\"variationIdentifier\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex items-center justify-between pa2 br2 ba b--secondary-40 font-md font-medium primary mb2 bg-secondary--10--hover\"],[12],[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]],null]],[\"variation\"],false,[\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "additive-content/templates/instance/styles/style/block/index.hbs",
    "isStrictMode": false
  });
});