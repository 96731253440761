define("additive-content/components/aw-configurator/content-editor/sidebar", ["exports", "@additive-apps/configurator/components/aw-configurator/content-editor/sidebar"], function (_exports, _sidebar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sidebar.default;
    }
  });
});