define("additive-content/routes/instance/styles/style/block", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceStylesIndexStyleBlockRoute extends _route.default {
    async model(params) {
      var _blocks;
      let blocks = this.store.peekAll('style-block');
      if (!((_blocks = blocks) !== null && _blocks !== void 0 && _blocks.length)) {
        const style = this.modelFor('instance.styles.style');
        blocks = await this.store.findAll('style-block', {
          adapterOptions: {
            style: style.id
          }
        });
      }
      const blockVariations = blocks.filterBy('blockIdentifier', params.block_id).sortBy('variationPosition');
      const block = {
        name: blockVariations[0].blockName,
        identifier: blockVariations[0].blockIdentifier
      };
      return {
        block,
        variations: blockVariations
      };
    }
  }
  _exports.default = InstanceStylesIndexStyleBlockRoute;
});