define("additive-content/mirage/models/landing-page-block", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Model.extend({
    landingPage: (0, _emberCliMirage.belongsTo)(),
    styleBlock: (0, _emberCliMirage.belongsTo)()
  });
});