define("additive-content/templates/instance/settings/legal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NxnVHiW4",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"onDidInsert\"]]],null],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@currentOrganization\",\"@settings\",\"@setSettings\",\"@isReadOnly\"],[[30,0,[\"currentOrganization\"]],[30,0,[\"settings\"]],[30,0,[\"setSettings\"]],[30,0,[\"settingsController\",\"isReadOnly\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"did-insert\",\"ac-settings/legal\"]]",
    "moduleName": "additive-content/templates/instance/settings/legal.hbs",
    "isStrictMode": false
  });
});