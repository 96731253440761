define("additive-content/mirage/scenarios/organization", ["exports", "additive-content/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.starter = _exports.professional = _exports.enterprise = _exports.DEFAULTS = void 0;
  const DEFAULTS = _exports.DEFAULTS = {
    contentLanguages: ['de', 'en', 'it'],
    defaultLanguage: 'de'
  };
  const accountApiRequests = (server, orgSlug) => {
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations`, schema => {
      return schema.organizations.all();
    });
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations/${orgSlug}`, schema => {
      return schema.organizations.findBy({
        id: orgSlug
      });
    });
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations/${orgSlug}/apps`, () => {
      return {
        apps: [{
          id: 2,
          name: 'voucher',
          planName: 'starter_monthly',
          active: true,
          trialEndsAt: '3018-09-11'
        }, {
          id: 3,
          name: 'social-wall',
          planName: 'starter_monthly',
          trialEndsAt: '2010-09-11',
          active: true
        }]
      };
    });
  };
  const starter = _exports.starter = function starter(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'starter_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
  const professional = _exports.professional = function professional(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'professional_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
  const enterprise = _exports.enterprise = function enterprise(server) {
    let orgSlug = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'test-org';
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'enterprise_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
});