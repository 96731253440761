define("additive-content/templates/instance/contents/quotes/categories", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "IEDv/8NG",
    "block": "[[[8,[39,0],null,[[\"@contentType\",\"@contentTypeRoute\"],[\"quote\",\"quotes\"]],null],[1,\"\\n\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"ac-contents/categories\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/quotes/categories.hbs",
    "isStrictMode": false
  });
});