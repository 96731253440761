define("additive-content/validators/surveyOptions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateQuestionOptionLength = validateQuestionOptionLength;
  _exports.validateQuestionOptionUniqueness = validateQuestionOptionUniqueness;
  /**
   *  @module Validators
   */

  /**
   * Checks whether the options are set only once per survey
   *
   * @function validateQuestionOptionUniqueness
   *
   * @return {Boolean|ValidationErr}
   */
  function validateQuestionOptionUniqueness() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      message = false
    } = options;
    return (key, newValue) => {
      if (!Array.isArray(newValue)) {
        return false;
      }
      const usedOptionIds = newValue.map(question => {
        var _question$options;
        return [...(question === null || question === void 0 || (_question$options = question.options) === null || _question$options === void 0 ? void 0 : _question$options.map(option => option.id))];
      }).flat();
      return usedOptionIds.length !== usedOptionIds.uniq().length ? message : true;
    };
  }

  /**
   * Checks whether each question has at least 2 options
   *
   * @function validateQuestionOptionLength
   *
   * @return {Boolean|ValidationErr}
   */
  function validateQuestionOptionLength() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      message = false
    } = options;
    return (key, newValue) => {
      if (!Array.isArray(newValue)) {
        return false;
      }
      const questionsWithLessOptions = newValue.map(question => {
        var _question$options2;
        return [...(question === null || question === void 0 || (_question$options2 = question.options) === null || _question$options2 === void 0 ? void 0 : _question$options2.map(option => option.id))];
      }).filter(questionOptions => questionOptions.length < 2);
      return questionsWithLessOptions.length ? message : true;
    };
  }
});