define("additive-content/validations/event", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/validate-if", "@additive-apps/utils/validators/date"], function (_exports, _validators, _validateIf, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventValidation = void 0;
  const eventValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const dateAfterMessage = intl.t('errors.endDateAfter');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      startsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      endsAt: [(0, _date.validateDateDependency)({
        on: 'startsAt',
        type: 'after',
        allowBlank: true,
        message: dateAfterMessage
      })],
      'rrule.byDay': (0, _validateIf.default)({
        validate: [(0, _validators.validateLength)({
          min: 1,
          allowBlank: false,
          message: presenceMessage
        })],
        if: (key, newValue, oldValue, changes) => {
          var _changes$rrule;
          return ((_changes$rrule = changes.rrule) === null || _changes$rrule === void 0 ? void 0 : _changes$rrule.freq) === 'WEEKLY';
        },
        firstErrorMessage: true
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.eventValidation = eventValidation;
});