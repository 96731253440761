define("additive-content/mirage/routes/landing-pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LandingPages = void 0;
  const LandingPages = server => {
    server.get(`/landing-pages`, schema => {
      return schema.landingPages.all();
    });
    server.get(`/landing-pages/:id`, (schema, _ref) => {
      let {
        params: {
          id
        }
      } = _ref;
      return schema.landingPages.find(id);
    });
    server.get(`/landing-pages/:id/blocks`, (schema, _ref2) => {
      let {
        params: {
          id
        }
      } = _ref2;
      return schema.landingPageBlocks.where({
        landingPageId: id
      });
    });
    server.put(`/landing-pages/:id/blocks`, (schema, _ref3) => {
      let {
        params: {
          id
        }
      } = _ref3;
      return schema.landingPageBlocks.where({
        landingPageId: id
      });
    });
    server.get(`/landing-pages/:id/preview`, () => {
      return `<html><head><style>p {font-size: 24px;}</style></head><body><div style="padding:54px;">
              <div style="background:red;height:800px;width:600px;max-width: 100%;padding:48px;margin:0 auto;box-sizing:border-box;"><p>Platzhalter-Text</p></div>
            </div></body></html>`;
    });
  };
  _exports.LandingPages = LandingPages;
});