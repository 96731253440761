define("additive-content/validations/tour", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tourValidation = void 0;
  const tourValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const integerMessage = intl.t('errors.integer');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      type: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      departureLocation: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      tourDescription: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      duration: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      })],
      difficulty: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      })],
      length: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      altitudeAscent: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      altitudeDescent: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      altitudeStart: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      altitudeMax: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: true,
        integer: true,
        message: integerMessage
      }),
      slug: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.tourValidation = tourValidation;
});