define("additive-content/serializers/contents", ["exports", "additive-content/serializers/application", "additive-content/utils/currency-serialization", "ember-inflector"], function (_exports, _application, _currencySerialization, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NULLABLE_PROPERTIES = ['stays', 'image', 'images', 'duration', 'basePrice', 'amount', 'sketches', 'downloads', 'maxStays', 'occupancyDefault', 'labelSymbol'];
  const AMOUNT_PROPERTIES = ['amount', 'price', 'discountedPrice', 'basePrice', 'minAmount', 'maxAmount'];
  const MEDIA_PROPERTIES = ['images', 'sketches', 'downloads', 'media'];

  /**
   * Extends the application serializer with the currency transformation and implements
   * the sorting of the images.
   *
   * @class ContentsAdapter
   */
  var _default = _exports.default = _application.default.extend({
    nullableProperties: NULLABLE_PROPERTIES,
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties and sort images
      contents.forEach(content => {
        MEDIA_PROPERTIES.forEach(property => {
          var _content$property;
          content[property] = (_content$property = content[property]) === null || _content$property === void 0 ? void 0 : _content$property.sort((a, b) => a.position - b.position);
        });
        AMOUNT_PROPERTIES.forEach(property => {
          if (content[property]) {
            content[property] = (0, _currencySerialization.centsToAmount)(content[property]).toString();
          }
        });
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    serialize(snapshot, options) {
      var _json$tags;
      let json = this._super(snapshot, options);

      // set position of images
      MEDIA_PROPERTIES.forEach(property => {
        var _json$property;
        json[property] = (_json$property = json[property]) === null || _json$property === void 0 ? void 0 : _json$property.map((item, index) => {
          item.position = index;
          return item;
        });
      });

      // transform currency properties to cents
      AMOUNT_PROPERTIES.forEach(property => {
        if (json[property]) {
          json[property] = (0, _currencySerialization.amountToCents)(json[property]);
        }
      });

      // tranform tags to string array
      json.tags = (_json$tags = json.tags) === null || _json$tags === void 0 ? void 0 : _json$tags.map(tag => tag.name ? tag.name : tag);
      return json;
    },
    keyForRelationship(key, relationship) {
      if (relationship === 'belongsTo') {
        return `${key}Id`;
      }
      if (relationship === 'hasMany') {
        return `${(0, _emberInflector.singularize)(key)}Ids`;
      }
      return key;
    }
  });
});