define("additive-content/utils/constants/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAvailableLandingPageBlocks = _exports.LANDING_PAGE_TEMPLATE_SALES_2 = _exports.LANDING_PAGE_TEMPLATE_SALES_1 = _exports.LANDING_PAGE_TEMPLATE_AWARENESS = _exports.LANDING_PAGE_TEMPLATES = _exports.LANDING_PAGE_LAST_MINUTE_DEFAULT_TEXTS = _exports.LANDING_PAGE_BLOCKS_SALES_2 = _exports.LANDING_PAGE_BLOCKS_SALES_1 = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_2 = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_1 = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_AWARENESS = _exports.LANDING_PAGE_BLOCKS_AWARENESS = _exports.DEFAULT_REFERRAL_MARKETING_AUTOMATION_DATA = _exports.DEFAULT_ACID_DATA = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_SUBSCRIPTION = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_PREDEFINED = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_DEFAULT = void 0;
  const LANDING_PAGE_TEMPLATE_AWARENESS = _exports.LANDING_PAGE_TEMPLATE_AWARENESS = 'awareness';
  const LANDING_PAGE_TEMPLATE_SALES_1 = _exports.LANDING_PAGE_TEMPLATE_SALES_1 = 'sales1';
  const LANDING_PAGE_TEMPLATE_SALES_2 = _exports.LANDING_PAGE_TEMPLATE_SALES_2 = 'sales2';
  const LANDING_PAGE_TEMPLATES = _exports.LANDING_PAGE_TEMPLATES = [LANDING_PAGE_TEMPLATE_AWARENESS, LANDING_PAGE_TEMPLATE_SALES_1, LANDING_PAGE_TEMPLATE_SALES_2];
  const LANDING_PAGE_LAST_MINUTE_DEFAULT_TEXTS = _exports.LANDING_PAGE_LAST_MINUTE_DEFAULT_TEXTS = {
    de: '<h1>Last Minute Urlaubsglück!</h1><p>Lust auf einen Spontan-Urlaub? Stöbern Sie durch unsere letzten verfügbaren Zimmer und Suiten und sichern Sie sich eine wertvolle Auszeit zum Vorteilspreis!</p>',
    it: '<h1>Vacanza last minute!</h1><p>Avete voglia di una vacanza spontanea? Date uno sguardo alle ultime camere e suite disponibili e concedetevi una vacanza straordinaria a prezzi imbattibili!</p>',
    en: '<h1>Vacation Last Minute!</h1><p>Fancy a spontaneous getaway? Browse through our last available rooms and suites and secure yourself a valuable time-out at a special price!</p>'
  };
  const LANDING_PAGE_BLOCKS_AWARENESS = _exports.LANDING_PAGE_BLOCKS_AWARENESS = ['usps.simple', 'quotes.small', 'portal_scores.simple', 'image_gallery.simple', 'text.simple', 'rooms.simple', 'text.simple', 'usps.simple', 'image_gallery.simple', 'form.framed'];
  const LANDING_PAGE_BLOCKS_SALES_1 = _exports.LANDING_PAGE_BLOCKS_SALES_1 = ['usps.simple', 'quotes.small', 'portal_scores.simple', 'image_gallery.simple', 'text.simple', 'last_minute_offers.simple', 'text.simple', 'rooms.simple', 'text.simple', 'offers.simple', 'faqs.simple', 'cta.framed', 'form.framed'];
  const LANDING_PAGE_BLOCKS_SALES_2 = _exports.LANDING_PAGE_BLOCKS_SALES_2 = ['image_gallery.simple', 'quotes.small', 'portal_scores.simple', 'text.simple', 'usps.simple', 'text.simple', 'last_minute_offers.simple', 'text.simple', 'rooms.simple', 'text.simple', 'offers.simple', 'faqs.simple', 'cta.framed', 'form.framed'];
  const LANDING_PAGE_BLOCKS_DEFAULT_VALUES_AWARENESS = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_AWARENESS = {
    header: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    marketing_automation: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'usps-1': {
      content: {
        show_categories: false
      }
    },
    quotes: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true,
        padding_top: 80
      }
    },
    portal_scores: {
      style: {
        background_color: 'accent',
        tile_use_contrast_color: true,
        padding_bottom: 80
      }
    },
    'image_gallery-1': {
      style: {
        padding_top: 0,
        padding_bottom: 0,
        show_image_galleries: false
      }
    },
    'text-1': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        padding_top: 80
      }
    },
    'text-2': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true,
        padding_top: 80
      }
    },
    'usps-2': {
      content: {
        show_categories: false
      },
      style: {
        show_slider: true,
        padding_top: 0,
        padding_bottom: 80,
        background_color: 'accent'
      }
    },
    'image_gallery-2': {
      style: {
        padding_top: 0,
        padding_bottom: 0,
        show_image_galleries: false
      }
    },
    form: {
      content: {
        banner_action: 'scroll_to_form'
      }
    }
  };
  const LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_1 = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_1 = {
    header: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    marketing_automation: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    usps: {
      content: {
        show_categories: true
      }
    },
    quotes: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true,
        padding_top: 80
      }
    },
    portal_scores: {
      style: {
        background_color: 'accent',
        tile_use_contrast_color: true,
        padding_bottom: 80
      }
    },
    'text-1': {
      content: {
        text: LANDING_PAGE_LAST_MINUTE_DEFAULT_TEXTS
      },
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'text-2': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'text-3': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true
      }
    },
    offers: {
      style: {
        background_color: 'accent',
        use_contrast_color: true
      }
    },
    faqs: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    cta: {
      style: {
        padding_bottom: 20,
        alignment_mobile: 'center'
      }
    },
    form: {
      content: {
        banner_action: 'scroll_to_form'
      },
      style: {
        padding_top: 20
      }
    }
  };
  const LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_2 = _exports.LANDING_PAGE_BLOCKS_DEFAULT_VALUES_SALES_2 = {
    header: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    marketing_automation: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    quotes: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'text-1': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true
      }
    },
    usps: {
      content: {
        show_categories: true
      },
      style: {
        background_color: 'accent'
      }
    },
    'text-2': {
      content: {
        text: LANDING_PAGE_LAST_MINUTE_DEFAULT_TEXTS
      },
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'text-3': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    'text-4': {
      style: {
        alignment: 'center',
        alignment_mobile: 'center',
        background_color: 'accent',
        use_contrast_color: true
      }
    },
    offers: {
      style: {
        background_color: 'accent',
        use_contrast_color: true
      }
    },
    faqs: {
      style: {
        alignment: 'center',
        alignment_mobile: 'center'
      }
    },
    cta: {
      style: {
        padding_bottom: 20,
        alignment_mobile: 'center'
      }
    },
    form: {
      content: {
        banner_action: 'scroll_to_form'
      },
      style: {
        padding_top: 20
      }
    }
  };
  const getAvailableLandingPageBlocks = type => {
    switch (type) {
      case 'subscription':
        return AVAILABLE_LANDING_PAGE_BLOCKS_SUBSCRIPTION;
      case 'journal':
      case 'newsletter':
      case 'referral_referrer':
      case 'referral_subscriber':
      case 'survey':
      case 'voucher':
        return AVAILABLE_LANDING_PAGE_BLOCKS_PREDEFINED;
      default:
        return AVAILABLE_LANDING_PAGE_BLOCKS_DEFAULT;
    }
  };
  _exports.getAvailableLandingPageBlocks = getAvailableLandingPageBlocks;
  const AVAILABLE_LANDING_PAGE_BLOCKS_SUBSCRIPTION = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_SUBSCRIPTION = [['text'], ['spacer']];
  const AVAILABLE_LANDING_PAGE_BLOCKS_PREDEFINED = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_PREDEFINED = [['text'], ['image_gallery'], ['usps'], ['spacer']];
  const AVAILABLE_LANDING_PAGE_BLOCKS_DEFAULT = _exports.AVAILABLE_LANDING_PAGE_BLOCKS_DEFAULT = [['text'], ['image_gallery'], ['room', 'rooms'], ['offer', 'offers'], ['last_minute_offers'], ['quotes', 'faqs', 'usps', 'portal_scores'], ['cta', 'form'], ['journal', 'newsletter', 'voucher'], ['spacer']];
  const DEFAULT_ACID_DATA = _exports.DEFAULT_ACID_DATA = {
    person: {
      email: 'max.mustermann@additive.eu',
      language: 'de',
      givenName: 'Max',
      familyName: 'Mustermann',
      salutation: 'Guten Tag',
      psychographics: []
    },
    subscriptions: ['newsletter', 'direct_marketing', 'follow_up_marketing', 'exclusion_list']
  };
  const DEFAULT_REFERRAL_MARKETING_AUTOMATION_DATA = _exports.DEFAULT_REFERRAL_MARKETING_AUTOMATION_DATA = {
    campaign: {
      language: 'de',
      code: 'referral-giveaway'
    },
    person: {
      salutation: `Guten Tag`,
      first_name: 'Max',
      last_name: 'Mustermann',
      gender: 'm',
      email: 'max.mustermann@additive.eu',
      country_code: null,
      language: 'de'
    },
    contents: {
      blocks: {
        referral: {
          column_left: '<h1>Überschrift</h1><br><br><p>Ein Text zu Demonstrationszwecken</p>',
          column_right: '<p>Ein Text zu Demonstrationszwecken mit einer Liste<ul><li>ein Listenelement</li><li>ein weiteres Listenelement</li></ul>',
          voucher: {
            total: 2,
            remaining: 2
          }
        }
      }
    }
  };
});