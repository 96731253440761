define("additive-content/helpers/ui-country-name", ["exports", "@additive-apps/ui/helpers/ui-country-name"], function (_exports, _uiCountryName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiCountryName.default;
    }
  });
  Object.defineProperty(_exports, "uiCountryName", {
    enumerable: true,
    get: function get() {
      return _uiCountryName.uiCountryName;
    }
  });
});