define("additive-content/routes/instance/contents/events/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsEventsIndexRoute extends _route.default {
    resetController(controller, isExiting) {
      if (isExiting) {
        // reset qps
        controller.set('category', null);
        controller.set('dateFrom', null);
        controller.set('dateUntil', null);
      }
    }
  }
  _exports.default = InstanceContentsEventsIndexRoute;
});