define("additive-content/validations/settings/legal", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.legalSettingsValidation = void 0;
  const legalSettingsValidation = intl => {
    const regex = /(http(s)?:\/\/.)+(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
    return {
      'disclaimerLink.value': (0, _validators.validateFormat)({
        regex,
        message: intl.t('errors.invalidURL'),
        allowBlank: true
      }),
      'privacyLink.value': (0, _validators.validateFormat)({
        regex,
        message: intl.t('errors.invalidURL'),
        allowBlank: true
      })
    };
  };
  _exports.legalSettingsValidation = legalSettingsValidation;
});