define("additive-content/templates/instance/contents/frequently-asked-questions/index/frequently-asked-question", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YtcMs1h6",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@routeName\",\"@model\"],[\"frequently-asked-questions\",\"frequently-asked-question\",\"frequently-asked-question\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"description\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.frequentlyAskedQuestions.frequentlyAskedQuestion.properties.answer\"],null],[30,0,[\"model\",\"description\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"_allInterestTags\",\"length\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@label\"],[[28,[37,3],[\"contents.common.interestTags.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"_allInterestTags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"if\",\"ac-contents/detail/wrapper\",\"t\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/frequently-asked-questions/index/frequently-asked-question.hbs",
    "isStrictMode": false
  });
});