define("additive-content/helpers/currency-symbol", ["exports", "@additive-apps/utils/helpers/currency-symbol"], function (_exports, _currencySymbol) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "currencySymbol", {
    enumerable: true,
    get: function get() {
      return _currencySymbol.currencySymbol;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _currencySymbol.default;
    }
  });
});