define("additive-content/templates/instance/contents/webcams/index/webcam", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gDIcutHB",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@model\",\"@hasExternalImage\"],[\"webcams\",\"webcam\",[30,0,[\"model\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"model\",\"description\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@label\",\"@content\"],[[28,[37,3],[\"contents.common.properties.description\"],null],[30,0,[\"model\",\"description\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"url\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@label\"],[[28,[37,3],[\"contents.webcams.webcam.properties.url\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[10,3],[15,6,[30,0,[\"model\",\"url\"]]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[14,0,\"font-md blue\"],[12],[1,\"\\n          \"],[1,[30,0,[\"model\",\"url\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"tags\",\"length\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@label\"],[[28,[37,3],[\"contents.common.properties.tags\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"tags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"if\",\"ac-contents/detail/wrapper\",\"t\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/webcams/index/webcam.hbs",
    "isStrictMode": false
  });
});