define("additive-content/validations/page", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/validate-if"], function (_exports, _validators, _validateIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pageValidation = void 0;
  const URL_REGEX = /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  const pageValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const urlFormatMessage = intl.t('errors.url');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      }),
      link: [
      // validate presence of link if linkType is landing_page
      (0, _validateIf.default)({
        validate: (0, _validators.validatePresence)({
          presence: true,
          message: presenceMessage
        }),
        if: (key, newValue, oldValue, changes, content) => {
          return Object.getOwnPropertyNames(changes).includes('linkType') ? changes.linkType === 'landing_page' : content.linkType === 'landing_page';
        },
        firstErrorMessage: true
      }),
      // validate format of link if linkType is external_link
      (0, _validateIf.default)({
        validate: (0, _validators.validateFormat)({
          regex: URL_REGEX,
          allowBlank: true,
          message: urlFormatMessage
        }),
        if: (key, newValue, oldValue, changes, content) => {
          return Object.getOwnPropertyNames(changes).includes('linkType') ? changes.linkType === 'external_link' : content.linkType === 'external_link';
        },
        firstErrorMessage: true
      })]
    };
  };
  _exports.pageValidation = pageValidation;
});