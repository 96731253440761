define("additive-content/validations/category", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.categoryValidation = void 0;
  const categoryValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxLength: 200
    });
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      description: (0, _validators.validateLength)({
        max: 200,
        message: maxLengthMessage
      })
    };
  };
  _exports.categoryValidation = categoryValidation;
});