define("additive-content/validations/product", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.productValidation = void 0;
  const productValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const numberMessage = intl.t('errors.gtZero');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      amount: (0, _validators.validateNumber)({
        allowBlank: true,
        gt: 0,
        message: numberMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.productValidation = productValidation;
});