define("additive-content/instance-initializers/google-analytics", ["exports", "@additive-apps/jam-ember/instance-initializers/google-analytics"], function (_exports, _googleAnalytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _googleAnalytics.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _googleAnalytics.initialize;
    }
  });
});