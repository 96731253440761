define("additive-content/adapters/timespan-room", ["exports", "additive-content/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TimespanRoomAdapter extends _application.default {
    urlForMultiple(_, snapshot) {
      var _snapshot$adapterOpti, _snapshot$adapterOpti2;
      let url = this.buildURL();
      if (snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti = snapshot.adapterOptions) !== null && _snapshot$adapterOpti !== void 0 && _snapshot$adapterOpti.offer && snapshot !== null && snapshot !== void 0 && (_snapshot$adapterOpti2 = snapshot.adapterOptions) !== null && _snapshot$adapterOpti2 !== void 0 && _snapshot$adapterOpti2.timespan) {
        const {
          offer,
          timespan
        } = snapshot.adapterOptions;
        url += `/offers/${offer}/timespans/${timespan}/rooms`;
      }
      return url;
    }
    urlForSingle(id, modelName, snapshot) {
      return `${this.urlForMultiple(modelName, snapshot)}/${id}`;
    }
    urlForFindAll() {
      return this.urlForMultiple(...arguments);
    }
    urlForFindRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForCreateRecord() {
      return this.urlForMultiple(...arguments);
    }
    urlForUpdateRecord() {
      return this.urlForSingle(...arguments);
    }
    urlForDeleteRecord() {
      return this.urlForSingle(...arguments);
    }
  }
  _exports.default = TimespanRoomAdapter;
});