define("additive-content/serializers/landing-page", ["exports", "additive-content/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LandingPageSerializer extends _application.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // check if payload contains array or single content
      const firstKey = Object.keys(payload)[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];
      contents.forEach(content => {
        if (!content.title) {
          content.title = null;
        }
        if (!content.description) {
          content.description = null;
        }
        if (!content.slug) {
          content.slug = null;
        }
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
  }
  _exports.default = LandingPageSerializer;
});