define("additive-content/templates/instance/contents/surveys/index/survey/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Y4r/V0S4",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@validation\",\"@isReadOnly\",\"@categorizableType\",\"@modelName\",\"@contentTypeRoute\"],[[30,0,[\"model\"]],[30,0,[\"validation\"]],[30,0,[\"isViewer\"]],\"survey\",\"survey\",\"surveys\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[16,\"data-test-survey-input-name\",true]],[[\"@label\",\"@value\",\"@isReadOnly\",\"@errorMessage\",\"@isTouched\"],[[28,[37,2],[\"contents.common.properties.title\"],null],[30,1,[\"changeset\",\"name\"]],[30,1,[\"isReadOnly\"]],[30,1,[\"changeset\",\"error\",\"name\",\"validation\"]],[30,1,[\"isTouched\"]]]],null],[1,\"\\n\\n    \"],[8,[39,3],null,[[\"@changeset\",\"@isReadOnly\"],[[30,1,[\"changeset\"]],[30,1,[\"isReadOnly\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"      \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"detail\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,6],null,[[\"@changeset\",\"@isReadOnly\"],[[30,1,[\"changeset\"]],[30,1,[\"isReadOnly\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"contentView\"],false,[\"ac-contents/edit\",\"ui-input\",\"t\",\"ac-contents/surveys/question-edit\",\"component\",\"-outlet\",\"ac-contents/surveys/target-type-select\"]]",
    "moduleName": "additive-content/templates/instance/contents/surveys/index/survey/edit.hbs",
    "isStrictMode": false
  });
});