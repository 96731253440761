define("additive-content/validations/post", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.postValidation = _exports.postSettingsValidation = _exports.commentValidation = void 0;
  const URL_REGEX = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
  const postValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      title: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      text: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      personId: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      slug: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.postValidation = postValidation;
  const commentValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      text: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      personId: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.commentValidation = commentValidation;
  const postSettingsValidation = intl => {
    return {
      blogUrl: (0, _validators.validateFormat)({
        regex: URL_REGEX,
        allowBlank: true,
        message: intl.t('errors.url')
      }),
      path: (0, _validators.validateFormat)({
        regex: /{slug}/,
        allowBlank: false,
        message: intl.t('errors.slug')
      })
    };
  };
  _exports.postSettingsValidation = postSettingsValidation;
});