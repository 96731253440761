define("additive-content/templates/instance/contents/quotes/index/quote", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "XbwZoIl2",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@model\"],[\"quotes\",\"quote\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"score\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@classNames\"],[\"mb2\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,3],null,[[\"@label\",\"@content\"],[[28,[37,4],[\"contents.quotes.quote.score\"],null],[30,0,[\"score\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"footerLine\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@label\",\"@content\"],[[28,[37,4],[\"contents.quotes.quote.properties.footerLine\"],null],[30,0,[\"model\",\"footerLine\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"_allInterestTags\",\"length\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@label\"],[[28,[37,4],[\"contents.common.interestTags.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,6],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"_allInterestTags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"if\",\"ui-content-info\",\"ui-info-item\",\"t\",\"ac-contents/detail/wrapper\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/quotes/index/quote.hbs",
    "isStrictMode": false
  });
});