define("additive-content/validations/quote", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral", "additive-content/validators/decimalPositions"], function (_exports, _validators, _numeral, _decimalPositions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.quoteValidation = void 0;
  const SCORE_MAX_VALUE = 100;
  const quoteValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const scoreMaxMessage = intl.t('errors.scoreMax');
    const scoreMessage = intl.t('errors.score');
    const twoDecimalsMessage = intl.t('errors.twoDecimals');
    const greaterZeroMessage = intl.t('errors.gtZero');
    const greaterEqualZeroMessage = intl.t('errors.gteZero');
    const lessEqualMaxValueMessage = intl.t('errors.lteValue', {
      maxValue: SCORE_MAX_VALUE
    });
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      footerLine: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      scoreMax: [(0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      }), (0, _numeral.validateNumeral)({
        gte: 'score',
        allowBlank: true,
        message: scoreMessage
      }), (0, _numeral.validateNumeral)({
        lte: SCORE_MAX_VALUE,
        allowBlank: true,
        message: lessEqualMaxValueMessage
      }), (0, _decimalPositions.validateDecimalPositions)({
        allowBlank: true,
        message: twoDecimalsMessage
      })],
      score: [(0, _numeral.validateNumeral)({
        gte: 0,
        allowBlank: true,
        message: greaterEqualZeroMessage
      }), (0, _numeral.validateNumeral)({
        lte: 'scoreMax',
        allowBlank: true,
        message: scoreMaxMessage
      }), (0, _numeral.validateNumeral)({
        lte: SCORE_MAX_VALUE,
        allowBlank: true,
        message: lessEqualMaxValueMessage
      }), (0, _decimalPositions.validateDecimalPositions)({
        allowBlank: true,
        message: twoDecimalsMessage
      })],
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.quoteValidation = quoteValidation;
});