define("additive-content/mirage/factories/corporate-design", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    typography: () => ({
      p: {
        color: 'main',
        serif: false,
        weight: 300,
        fontSize: '18px',
        fontFamily: 'primary.normal',
        lineHeight: '24px'
      },
      h1: {
        color: 'main',
        serif: false,
        weight: 700,
        fontSize: '40px',
        fontFamily: 'primary.bold',
        lineHeight: '48px'
      },
      h2: {
        color: 'main',
        serif: false,
        weight: 700,
        fontSize: '32px',
        fontFamily: 'primary.bold',
        lineHeight: '40px'
      },
      h3: {
        color: 'main',
        serif: false,
        weight: 700,
        fontSize: '30px',
        fontFamily: 'primary.bold',
        lineHeight: '36px'
      },
      label: {
        color: 'accent',
        serif: false,
        weight: 300,
        fontSize: '11px',
        fontFamily: 'primary.normal',
        lineHeight: '16px'
      },
      small: {
        color: 'main',
        serif: false,
        weight: 300,
        fontSize: '14px',
        fontFamily: 'primary.normal',
        lineHeight: '20px'
      },
      button: {
        color: 'accent',
        serif: false,
        weight: 300,
        fontSize: '14px',
        fontFamily: 'primary.normal',
        lineHeight: '20px'
      }
    }),
    colors: () => ({
      main: {
        color: '#575757',
        contrastColor: '#ffffff'
      },
      accent: {
        color: '#967E6E',
        contrastColor: '#ffffff'
      },
      ambient: {
        color: '#ffffff',
        contrastColor: '#575757'
      }
    }),
    logoUrl: 'organizations/testhotel-post-com/corporate-design/logo-default',
    alternativeLogoUrl: 'organizations/testhotel-post-com/corporate-design/logo-alternative',
    logoUrls: () => ({
      '220x110': 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvdGVzdGhvdGVsLXBvc3QtY29tL2NvcnBvcmF0ZS1kZXNpZ24vbG9nby1kZWZhdWx0LTIyMHgxMTA/dHM9MTY0NDI0Njk5Nw==\u0026ts=1644246997',
      '0x480': 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvdGVzdGhvdGVsLXBvc3QtY29tL2NvcnBvcmF0ZS1kZXNpZ24vbG9nby1kZWZhdWx0LTB4NDgwP3RzPTE2NDQyNDY5OTc=\u0026ts=1644246997'
    }),
    alternativeLogoUrls: () => ({
      '220x110': 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvdGVzdGhvdGVsLXBvc3QtY29tL2NvcnBvcmF0ZS1kZXNpZ24vbG9nby1hbHRlcm5hdGl2ZS0yMjB4MTEwP3RzPTE2NDQyNDY5OTc=\u0026ts=1644246997',
      '0x480': 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvdGVzdGhvdGVsLXBvc3QtY29tL2NvcnBvcmF0ZS1kZXNpZ24vbG9nby1hbHRlcm5hdGl2ZS0weDQ4MD90cz0xNjQ0MjQ2OTk3\u0026ts=1644246997'
    }),
    fonts: () => ({
      secondary: {
        id: '8d595c4e-a98b-46fd-99f2-5e475122acc9',
        fontFamily: 'Test',
        serif: false
      },
      primary: {
        id: 'b005542e-f553-4d15-85c3-0870a97cdede',
        fontFamily: 'Museo Slabby',
        serif: false,
        light: {
          id: '5856e43c-d0d9-4c83-829e-868a9a160ac7',
          fontFamily: 'museo-slab',
          weight: 200,
          url: 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvNjgyMi9mb250cy9iMDA1NTQyZS1mNTUzLTRkMTUtODVjMy0wODcwYTk3Y2RlZGUvbGlnaHQub3RmP3RzPTE2NDQyNDY5OTc=\u0026ts=1644246997',
          mimeType: 'font/otf'
        },
        normal: {
          id: 'c96def7e-e848-4abd-89e0-c3b5670ab6ce',
          fontFamily: 'museo-slab',
          weight: 300,
          url: 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvNjgyMi9mb250cy9iMDA1NTQyZS1mNTUzLTRkMTUtODVjMy0wODcwYTk3Y2RlZGUvbm9ybWFsLm90Zj90cz0xNjQ0MjQ2OTk3\u0026ts=1644246997',
          mimeType: 'font/otf'
        },
        bold: {
          id: '9dc852bd-c81a-43a6-b7b2-349743ac1453',
          fontFamily: 'museo-slab',
          weight: 700,
          url: 'https://additive-account.herokuapp.com/api/redirect?destination=aHR0cHM6Ly9hZGRpdGl2ZS1hY2NvdW50LnMzLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL29yZ2FuaXphdGlvbnMvNjgyMi9mb250cy9iMDA1NTQyZS1mNTUzLTRkMTUtODVjMy0wODcwYTk3Y2RlZGUvYm9sZC5vdGY/dHM9MTY0NDI0Njk5Nw==\u0026ts=1644246997',
          mimeType: 'font/otf'
        }
      }
    })
  });
});