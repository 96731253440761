define("additive-content/templates/instance/contents/webcams", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QSAWNe5a",
    "block": "[[[8,[39,0],null,[[\"@filterName\",\"@modelName\",\"@categorizableType\",\"@contentsRoute\"],[\"webcams\",\"webcam\",\"webcam\",\"instance.contents.webcams.index\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"tabs\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.contents.webcams.index\",[28,[37,1],[\"routes.contents.webcams.title\"],null]]],null],[1,\"\\n    \"],[8,[30,2,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.contents.webcams.categories\",[28,[37,1],[\"routes.contents.categories.title\"],null]]],null],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"createDialog\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"item\"]],null,[[\"@icon\",\"@onClick\"],[\"webcam\",[28,[37,4],[[30,3,[\"goTo\"]],\"instance.contents.webcams.index.create\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"intro\"]],null,[[\"@size\",\"@title\",\"@description\"],[\"md\",[28,[37,1],[\"contents.webcams.webcam.title\"],null],[28,[37,1],[\"contents.common.newContent.webcams\"],null]]],null],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\\n    \"],[8,[30,3,[\"item\"]],null,[[\"@icon\",\"@onClick\"],[\"grid\",[28,[37,4],[[30,3,[\"goTo\"]],\"instance.contents.webcams.categories.create\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,5,[\"intro\"]],null,[[\"@size\",\"@title\"],[\"md\",[28,[37,1],[\"contents.common.properties.category\"],null]]],null],[1,\"\\n    \"]],[5]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],[\"detail\"],null],null,null,null]],[\"view\",\"tabs\",\"dialog\",\"item\",\"item\"],false,[\"ac-contents/main-view\",\"t\",\"component\",\"-outlet\",\"fn\"]]",
    "moduleName": "additive-content/templates/instance/contents/webcams.hbs",
    "isStrictMode": false
  });
});