define("additive-content/helpers/ui-parse-int", ["exports", "@additive-apps/ui/helpers/ui-parse-int"], function (_exports, _uiParseInt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiParseInt.default;
    }
  });
  Object.defineProperty(_exports, "uiParseInt", {
    enumerable: true,
    get: function get() {
      return _uiParseInt.uiParseInt;
    }
  });
});