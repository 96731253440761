define("additive-content/templates/instance/styles", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sLsG0qPZ",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.styles.title\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@description\",\"@size\",\"@isMultiline\"],[[28,[37,1],[\"routes.styles.title\"],null],[28,[37,1],[\"routes.styles.description\"],null],\"xl\",true]],null],[1,\"\\n  \"],[8,[30,1,[\"tabs\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"currentOrganization\",\"hasLandingPage\"]],[[[1,\"        \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.styles.index\",[28,[37,1],[\"routes.styles.styles\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.styles.widgets\",[28,[37,1],[\"routes.styles.widgets.title\"],null]]],null],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\",\"tabs\",\"header\"],false,[\"ui-content-views\",\"t\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/styles.hbs",
    "isStrictMode": false
  });
});