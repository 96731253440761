define("additive-content/templates/instance/contents/daily-menus/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MegPrZ95",
    "block": "[[[8,[39,0],null,[[\"@filterKey\",\"@modelName\",\"@detailRoute\",\"@descriptionProperty\",\"@initialCategory\",\"@initialDate\",\"@hideImage\"],[\"daily-menus\",\"daily-menu\",\"instance.contents.daily-menus.index.daily-menu.index\",\"categoryNames\",[30,0,[\"category\"]],[30,0,[\"dateFrom\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"menu\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filterKey\",\"@modelName\",\"@model\",\"@menuItem\",\"@editRoute\"],[\"daily-menus\",\"daily-menu\",[30,2],[30,1,[\"menuItem\"]],\"instance.contents.daily-menus.index.daily-menu.edit\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[\"list\",\"item\"],false,[\"ac-contents/calendar-list\",\"ac-contents/actions\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/daily-menus/index.hbs",
    "isStrictMode": false
  });
});