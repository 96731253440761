define("additive-content/components/ui-content", ["exports", "@additive-apps/ui/components/ui-content"], function (_exports, _uiContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uiContent.default;
    }
  });
});