define("additive-content/validations/room", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roomValidation = void 0;
  const roomValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const maxLengthMessage = intl.t('errors.maxLength', {
      maxLength: 40
    });
    const maxOccupancyMessage = intl.t('errors.maxOccupation');
    const minOccupancyMessage = intl.t('errors.minOccupation');
    const greaterZeroMessage = intl.t('errors.gtZero');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      description: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      occupancyMax: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gte: 'occupancyMin',
        allowBlank: true,
        message: maxOccupancyMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })],
      occupancyMin: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        lte: 'occupancyMax',
        allowBlank: true,
        message: minOccupancyMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })],
      occupancyDefault: [(0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      }), (0, _numeral.validateNumeral)({
        gte: 'occupancyMin',
        allowBlank: true,
        message: maxOccupancyMessage
      }), (0, _numeral.validateNumeral)({
        lte: 'occupancyMax',
        allowBlank: true,
        message: minOccupancyMessage
      })],
      basePrice: (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      }),
      priceInformation: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      slug: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      roomType: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      categories: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      label: (0, _validators.validateLength)({
        max: 40,
        message: maxLengthMessage
      }),
      images: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      }),
      sketches: (0, _validators.validateLength)({
        max: 10,
        message: intl.t('errors.images')
      })
    };
  };
  _exports.roomValidation = roomValidation;
});