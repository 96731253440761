define("additive-content/validators/decimalPositions", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateDecimalPositions = validateDecimalPositions;
  /**
   *  @module Validators
   */

  /**
   * Checks whether a given number has at most a specified amount of decimal positions
   *
   * @function validateDecimalPositions
   * @param {Object} options
   *
   * @param {String} options.allowBlank If true, skips validation if the value is empty
   * @param {String} options.maxDecimalPositions The max amount of decimal positions
   * @param {Object} changes
   * @param {Object} content
   *
   * @return {Boolean|ValidationErr}
   */
  function validateDecimalPositions() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      allowBlank,
      maxDecimalPositions = 2,
      message = false
    } = options;
    return (key, newValue) => {
      // if allowBlank is set and the value is empty skip validation
      if (allowBlank && (0, _utils.isEmpty)(newValue)) {
        return true;
      }
      const regex = new RegExp(`^\\d*([.|,]\\d{1,${maxDecimalPositions}}[0]*)?$`);
      return regex.test(`${newValue}`) ? true : message;
    };
  }
});