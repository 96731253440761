define("additive-content/templates/instance/contents/galleries/index/gallery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Hk0+Vdr5",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@model\"],[\"galleries\",\"gallery\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"contents.galleries.gallery.properties.media\"],null]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@changeset\",\"@resourceProperty\",\"@mediaType\",\"@isReadOnly\"],[[30,0,[\"model\"]],\"media\",null,true]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"_allInterestTags\",\"length\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@label\"],[[28,[37,2],[\"contents.common.interestTags.title\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@tags\",\"@isReadOnly\"],[[30,0,[\"model\",\"_allInterestTags\"]],true]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"ac-contents/detail/wrapper\",\"t\",\"ac-contents/detail/images\",\"if\",\"ui-tags\"]]",
    "moduleName": "additive-content/templates/instance/contents/galleries/index/gallery.hbs",
    "isStrictMode": false
  });
});