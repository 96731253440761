define("additive-content/templates/instance/contents/people/index/person", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dWtFz5ax",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@model\"],[\"people\",\"person\",[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@label\",\"@content\"],[[28,[37,2],[\"contents.people.person.properties.role\"],null],[30,0,[\"model\",\"role\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"description\"]],[[[1,\"      \"],[8,[39,1],null,[[\"@label\",\"@content\"],[[28,[37,2],[\"contents.common.properties.description\"],null],[30,0,[\"model\",\"description\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"Detail\"],false,[\"ac-contents/detail\",\"ac-contents/detail/wrapper\",\"t\",\"if\"]]",
    "moduleName": "additive-content/templates/instance/contents/people/index/person.hbs",
    "isStrictMode": false
  });
});