define("additive-content/routes/instance/contents/room-services/index/room-service", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    uiAppSettings: (0, _service.inject)(),
    renderTemplate() {
      this.render('instance/contents/room-services/index/room-service', {
        into: 'instance/contents/room-services',
        outlet: 'detail'
      });
    },
    model(params) {
      return this.store.findRecord('room-service', params.id);
    },
    setupController(controller, model) {
      this._super(...arguments);
      model.setAllInterestTags();
    },
    resetController(controller, isExiting) {
      if (isExiting) {
        var _controller$model, _controller$model2;
        this.uiAppSettings.setLocale(this.uiAppSettings.languages.defaultLanguage);
        return !((_controller$model = controller.model) !== null && _controller$model !== void 0 && _controller$model.get('isDestroyed')) && !((_controller$model2 = controller.model) !== null && _controller$model2 !== void 0 && _controller$model2.get('isDeleted')) && controller.model.reload();
      }
    }
  });
});