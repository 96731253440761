define("additive-content/serializers/application", ["exports", "@ember-data/serializer/rest", "@ember/utils"], function (_exports, _rest, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    /**
     * can be overwritten to prevent the serializer from
     * removing a property with a null value
     *
     * @property nullableProperties
     * @type {[String]}
     */
    nullableProperties: null,
    serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      Object.keys(json).forEach(key => (0, _utils.isNone)(json[key]) && (this.nullableProperties || []).indexOf(key) < 0 && delete json[key]);
      return json;
    },
    serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    },
    /**
     * adds the nullable properties to the payload, with value null
     */
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const modelName = Object.keys(payload)[0];
      const data = Array.isArray(payload[modelName]) ? payload[modelName] : [payload[modelName]];
      this.nullableProperties && data.forEach(model => {
        this.nullableProperties.forEach(property => {
          if (!model[property]) {
            model[property] = null;
          }
        });
      });
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});