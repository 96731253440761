define("additive-content/components/ac-contents/detail/wrapper", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ac-contents-detail-wrapper flex flex-column pb3 {{@class}}" ...attributes>
    <span class="secondary font-sm pb05">
      {{@label}}
    </span>
    <div class="font-md">
      {{#if this.content}}
        {{this.content}}
      {{else}}
        {{yield}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "g55cQUx8",
    "block": "[[[11,0],[16,0,[29,[\"ac-contents-detail-wrapper flex flex-column pb3 \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[10,1],[14,0,\"secondary font-sm pb05\"],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"font-md\"],[12],[1,\"\\n\"],[41,[30,0,[\"content\"]],[[[1,\"      \"],[1,[30,0,[\"content\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@class\",\"&attrs\",\"@label\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "additive-content/components/ac-contents/detail/wrapper.hbs",
    "isStrictMode": false
  });
  class AcContentsDetailWrapperComponent extends _component2.default {
    /**
     * formatted content
     *
     * @computed content
     */
    get content() {
      return this.args.content && (0, _template.htmlSafe)(this.args.content);
    }
  }
  _exports.default = AcContentsDetailWrapperComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AcContentsDetailWrapperComponent);
});