define("additive-content/routes/instance/contents/frequently-asked-questions/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceContentsFaqsIndexRoute extends _route.default {
    resetController(controller, isExiting) {
      if (isExiting) {
        // reset qps
        controller.set('category', null);
      }
    }
  }
  _exports.default = InstanceContentsFaqsIndexRoute;
});