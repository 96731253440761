define("additive-content/validations/last-minute-offer", ["exports", "ember-changeset-validations/validators", "@additive-apps/utils/validators/numeral"], function (_exports, _validators, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lastMinuteOfferValidation = _exports.lastMinuteOfferSettingsValidation = void 0;
  const lastMinuteOfferValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const originalPriceMessage = intl.t('errors.originalPrice');
    const discountedPriceMessage = intl.t('errors.discountedPrice');
    const greaterZeroMessage = intl.t('errors.gtZero');
    return {
      room: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      rateType: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      startDate: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      endDate: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      price: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 'discountedPrice',
        message: originalPriceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })],
      discountedPrice: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        lt: 'price',
        message: discountedPriceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        allowBlank: true,
        message: greaterZeroMessage
      })]
    };
  };
  _exports.lastMinuteOfferValidation = lastMinuteOfferValidation;
  const lastMinuteOfferSettingsValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    const percentMessage = intl.t('errors.percent');
    const integerMessage = intl.t('errors.integer');
    return {
      syncDaysBeforeArrival: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        integer: true,
        allowBlank: true,
        message: integerMessage
      })],
      syncStaysCount: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        integer: true,
        allowBlank: true,
        message: integerMessage
      })],
      syncDiscountPercentage: [(0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }), (0, _numeral.validateNumeral)({
        gt: 0,
        lt: 100,
        integer: true,
        allowBlank: true,
        message: percentMessage
      })]
    };
  };
  _exports.lastMinuteOfferSettingsValidation = lastMinuteOfferSettingsValidation;
});