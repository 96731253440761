define("additive-content/templates/instance/contents/rates/rates", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q7u31Iq/",
    "block": "[[[8,[39,0],null,[[\"@filterKey\",\"@modelName\",\"@descriptionProperty\",\"@detailRoute\",\"@hideImage\",\"@hideActions\"],[\"rates\",\"rate\",\"rate.name\",\"instance.contents.rates.rates.rate\",true,[30,0,[\"isViewer\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"menu\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@filterKey\",\"@modelName\",\"@model\",\"@menuItem\",\"@editRoute\"],[\"rates\",\"rate\",[30,2],[30,1,[\"menuItem\"]],\"instance.contents.rates.rates.rate.edit\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null]],[\"list\",\"item\"],false,[\"ui-list-view\",\"ac-contents/actions\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-content/templates/instance/contents/rates/rates.hbs",
    "isStrictMode": false
  });
});