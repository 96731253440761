define("additive-content/templates/instance/contents/portal-scores/index/portal-score", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "SxZcPVEr",
    "block": "[[[8,[39,0],null,[[\"@contentTypeRoute\",\"@modelName\",\"@canDuplicate\",\"@alwaysPublished\",\"@model\"],[\"portal-scores\",\"portal-score\",false,true,[30,0,[\"model\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@label\",\"@content\"],[[28,[37,2],[\"contents.portalScores.portalScore.properties.lastChangedAt\"],null],[28,[37,3],[[30,0,[\"model\",\"lastChangedAt\"]]],[[\"format\"],[[28,[37,4],null,[[\"de\",\"en\"],[\"dd.MM.yyyy\",\"dd-MM-yyyy\"]]]]]]]],null],[1,\"\\n\"],[41,[51,[30,0,[\"isViewer\"]]],[[[1,\"      \"],[8,[39,6],null,[[\"@icon\",\"@label\",\"@variant\",\"@onClick\"],[\"reload\",[28,[37,2],[\"global.actions.updateDate\"],null],\"primary\",[30,0,[\"updateDate\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"Detail\"],false,[\"ac-contents/detail\",\"ac-contents/detail/wrapper\",\"t\",\"date-format\",\"hash\",\"unless\",\"ui-action-button\"]]",
    "moduleName": "additive-content/templates/instance/contents/portal-scores/index/portal-score.hbs",
    "isStrictMode": false
  });
});