define("additive-content/validations/landing-page", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.landingPageValidation = _exports.duplicateLandingPageValidation = _exports.addLandingPageLangugageValidation = void 0;
  const landingPageValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      styleId: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.landingPageValidation = landingPageValidation;
  const duplicateLandingPageValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      name: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.duplicateLandingPageValidation = duplicateLandingPageValidation;
  const addLandingPageLangugageValidation = intl => {
    const presenceMessage = intl.t('errors.required');
    return {
      title: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      }),
      slug: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
  };
  _exports.addLandingPageLangugageValidation = addLandingPageLangugageValidation;
});