define("additive-content/routes/instance/styles/style/block/variation", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceStylesStyleBlockVariationRoute extends _route.default {
    model(params) {
      const model = this.modelFor('instance.styles.style.block');
      const style = this.modelFor('instance.styles.style');
      const variation = model.variations.findBy('variationIdentifier', params.variation_id);
      return {
        block: model.block,
        variation,
        style
      };
    }
    setupController(controller) {
      super.setupController(...arguments);
      const {
        variations
      } = this.modelFor('instance.styles.style.block');
      controller.siblingVariations = variations;
    }
  }
  _exports.default = InstanceStylesStyleBlockVariationRoute;
});